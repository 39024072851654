import React from "react";
import Breadcrumb from "./Breadcrumb";
import SetupCheckMark from "./SetupCheckMark";
import CreatePropertyModule from "./CreatePropertyModule";
import CapterraPixel from "../tracking/CapterraPixel";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import GuideyLogo from "../icons/GuideyLogo";
import "../styles/SetupFlow.css";

export default class DashboardWelcome extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    document.body.style.setProperty("--tidio-display", "none");
  };

  render() {
    const { SaveStatus, userData, linkedPropertiesLoaded } = this.props;
    const SectionTitle = "Create your first Property Guide";
    const userFirstname = userData ? userData.UserFirstname : "";

    return (
      <>
        <div className="setup-flow-headerbar-outer">
          <div className="setup-flow-headerbar">
            <GuideyLogo width={"120"} height={"auto"} />
          </div>
        </div>

        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <div className="welcome-banner-container">
              <div
                style={{
                  borderLeftColor: `var(--brandColour3)`,
                }}
                className={"welcome-banner"}
              >
                <div className="welcome-banner-header">
                  {`Welcome ${userFirstname}, your free trial has been activated`}
                </div>

                <div className="welcome-banner-point-container">
                  <SetupCheckMark
                    ItemText={
                      "All features are available during the trial period, no Ads, no limitations!"
                    }
                  />
                </div>
              </div>
            </div>

            <h1>{SectionTitle}</h1>

            <CreatePropertyModule
              {...this.props}
              SaveStatus={SaveStatus}
              SetupFlow={false}
              ItemType={"property"} // Defines which dispatch to invoke
              ContainerClassName={"infoitem-form-inner-container-full"}
              ItemLabel={"New Property"}
              ItemHeading={"Property Title"}
              InputLabelA={"Property guide name"}
              InputLabelB={"Unique weblink"}
              ValueA={""}
              FalseText={"Not entered"}
              PayloadA={"PropertyTitle"}
              PayloadB={"PropertyID"}
              userId={userData._id}
              linkedPropertiesLoaded={linkedPropertiesLoaded}
            />

            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Go to Dashboard"}
              PreviousPagePath={"/"}
              CurrentSectionTitle={SectionTitle}
            />
          </div>
          <CapterraPixel />
        </div>
      </>
    );
  }
}
