import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import { appConfig } from "../data/appConfig";
import Breadcrumb from "./Breadcrumb";
import NotificationBanner from "./NotificationBanner";
import PropertyDetailsSection from "./PropertyDetailsSection";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

class SharedContentHome extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);
  };

  checkIfChecked = (label, data) => {
    var filter = data.filter((Item) => {
      return Item && Item.categorylabel === label;
    });
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  onButtonClick = () => {
    this.props.history.push("/local-area-profiles");
  };

  render() {
    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    const {
      SaveStatus,
      systemCategories,
      SharedPropertyContentProfileId,
      sharedContent,
    } = this.props;

    if (sharedContent) {
      const CurrentSectionTitle = "Shared Content";

      const initialContentFilter = sharedContent.filter((item) => {
        return (
          item.DetailTitle ||
          item.DetailText ||
          item.DetailTextDescribe ||
          item.DetailTextLocate ||
          item.DetailTextOperate
        );
      });

      // Return all categories found in "Custom Content" database
      const allCustomCategories = initialContentFilter.map((item) => {
        return {
          categorylabel: item.CategoryLabel,
        };
      });

      // Filter out empty and unwanted category labels from "Custom Content"
      const filteredCustomCategories = allCustomCategories.filter((item) => {
        return item.categorylabel !== undefined && item.categorylabel !== null;
      });

      // Return all data from "Content Categories" database
      const allContentCategories = systemCategories;

      // Filter "Content Categories" by the categories found in "Custom Content"
      var firstFilterContentCategories = [];

      for (var firstArray in allContentCategories) {
        for (var firstFilter in filteredCustomCategories) {
          if (
            allContentCategories[firstArray].CategoryLabel ===
            filteredCustomCategories[firstFilter].categorylabel
          ) {
            firstFilterContentCategories.push({
              categorylabel: allContentCategories[firstArray].CategoryLabel,
              parentcategorylabel:
                allContentCategories[firstArray].ParentCategoryLabel,
            });
          }
        }
      }

      // Lookup parent categories from categories found above
      var secondFilterContentCategories = [];

      for (var secondArray in allContentCategories) {
        for (var secondFilter in firstFilterContentCategories) {
          if (
            allContentCategories[secondArray].CategoryLabel ===
            firstFilterContentCategories[secondFilter].parentcategorylabel
          ) {
            secondFilterContentCategories.push({
              categorylabel: allContentCategories[secondArray].CategoryLabel,
              parentcategorylabel:
                allContentCategories[secondArray].ParentCategoryLabel,
            });
          }
        }
      }

      // Return unique categories found in the above
      var uniqueContentCategories = [];
      secondFilterContentCategories.forEach(function (item) {
        var i = uniqueContentCategories.findIndex(
          (x) => x.categorylabel === item.categorylabel
        );
        if (i <= -1) {
          uniqueContentCategories.push({
            categorylabel: item.categorylabel,
          });
        }
      });

      return (
        <>
          <div className="canvas-with-header-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <Breadcrumb
                {...this.props}
                PreviousPageTitle={"Dashboard"}
                PreviousPagePath={"/"}
                CurrentSectionTitle={CurrentSectionTitle}
              />

              <div className="content-and-ad-banner-container">
                <div className="heading-and-image-container">
                  <h1>Shared Property Content</h1>

                  <div className="infopage-subheading">
                    Create and edit content to share across multiple properties
                  </div>

                  <NotificationBanner
                    Text={
                      "Save time! Any content you set up in this section can be shared across all of your properties with the click of a button"
                    }
                    DisplayButton={true}
                    ExternalLink={true}
                    ButtonText={"How it works"}
                    Target={`${appConfig.siteUrl}/help/shared-content`}
                    IconType={"Bell"}
                    IconColour={"#1e8fc1"}
                    TextColour={"#1e8fc1"}
                    ButtonColour={"#1e8fc1"}
                    BorderColour={"#1e8fc1"}
                    {...this.props}
                  />
                </div>
              </div>

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "arrival",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"Welcome and Arrival"}
                SectionText={"Welcome message and arrival information"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/arrival`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "place",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"About the Place"}
                SectionText={"Information about household items"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/place`}
                ParentCategoryLabel={"place-guide"}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "services",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"Services and Supplies"}
                SectionText={"Onsite services and additional supplies"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/services`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "store",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"Store"}
                SectionText={"Sell items to your guests"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/store`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "safety",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"Safety and Security"}
                SectionText={"Safety and security features"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/safety`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "about-us",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"About Us"}
                SectionText={"Information about the host"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/about-us`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "important-documents",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"Important documents"}
                SectionText={"Important documents relating to the property"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/important-documents`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "emergency-contacts",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"Emergency Contacts"}
                SectionText={"Emergency contact details"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/emergency-contacts`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <PropertyDetailsSection
                {...this.props}
                SectionType={"non-link"}
                IsChecked={this.checkIfChecked(
                  "emergency-contacts",
                  uniqueContentCategories
                )}
                IsRequired={false}
                ShowActionButton={false}
                SectionTitle={"FAQ"}
                SectionText={"Frequently Asked Questions"}
                SaveStatus={SaveStatus}
                propertyVanityId={vanityPropertyId}
                propertyId={SharedPropertyContentProfileId}
                Target={`/shared-content/${vanityPropertyId}/faq`}
              />

              <div className="infoitem-container">
                <div className="section-break-wrapper">
                  <div className="section-break"></div>
                </div>
              </div>

              <button
                className="create-new-button-inverted"
                onClick={this.onButtonClick}
              >
                Shared local area profiles
              </button>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (appLabel, propertyId) =>
      dispatch(getCustomContent(appLabel, propertyId)),
    setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  };
};

export default connect(null, mapDispatchToProps)(SharedContentHome);
