import React from "react";
import { connect } from "react-redux";
import { cdnRootUrl } from "../constants.js";
import { updateContent } from "../redux/actions/userActions";
import { createContent } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import { deleteUploadedFile } from "../redux/actions/userActions";
import InfoItemCheckMark from "./InfoItemCheckMark";
import InfoItemSubContent from "./InfoItemSubContent";
import InfoItemMultiTextEditor from "./InfoItemMultiTextEditor";
import DeleteContentButton from "./DeleteContentButton";
import PDFPreview from "./PDFPreview";
import "../styles/InfoItem.css";
import "../styles/CheckBox.css";
import CameraIcon from "../icons/CameraIcon";
import VideoIcon from "../icons/VideoIcon";
import BinIcon from "../icons/BinIcon";
import DocumentIcon from "../icons/DocumentIcon";

class InfoItemMulti extends React.Component {
  constructor(props) {
    super(props);
    const {
      DetailLabel,
      ItemUniqueId,
      systemContent,
      customContent,
      InfoType,
      isComponentShared,
      sharedContent,
      PropertyId,
    } = this.props;

    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    const propertyId = PropertyId ? PropertyId : null;

    // Filter custom content by vanity property id
    const customContentFilterOne = isComponentShared
      ? null
      : customContent.map((element) => {
          return {
            ...element,
            LinkedProperty: element.LinkedProperty.filter(
              (subElement) => subElement === propertyId
            ),
          };
        });
    const customContentFilterTwo = isComponentShared
      ? sharedContent
      : customContentFilterOne.filter((Item) => {
          return Item.LinkedProperty[0] === propertyId;
        });

    // Lookup and set ItemData
    const itemDataFilteredByLabel = customContentFilterTwo
      ? customContentFilterTwo.filter((Item) => {
          return Item.DetailLabel === DetailLabel;
        })
      : null;

    const itemDataFilteredById = customContentFilterTwo
      ? customContentFilterTwo.filter((Item) => {
          return Item._id === ItemUniqueId;
        })
      : null;

    var ItemData = null;
    if (InfoType === "system" && itemDataFilteredByLabel) {
      ItemData = itemDataFilteredByLabel[0];
    }
    if (InfoType === "custom" && itemDataFilteredById) {
      ItemData = itemDataFilteredById[0];
    }

    // Set Filenames so "null"s can be filtered out
    const imageFilename =
      ItemData && ItemData.DetailImage
        ? ItemData.DetailImage.substring(
            ItemData.DetailImage.lastIndexOf("/") + 1
          )
        : "";

    const VideoFilename =
      ItemData && ItemData.DetailVideo
        ? ItemData.DetailVideo.substring(
            ItemData.DetailVideo.lastIndexOf("/") + 1
          )
        : "";

    const documentFilename =
      ItemData && ItemData.DetailDocument
        ? ItemData.DetailDocument.substring(
            ItemData.DetailDocument.lastIndexOf("/") + 1
          )
        : "";

    // Use Detail label to lookup system content
    const systemContentFiltered = systemContent.filter((DisplayContent) => {
      return DisplayContent.DetailLabel === DetailLabel;
    });

    var ContentType = "";
    if (systemContentFiltered[0] && systemContentFiltered[0].ContentType) {
      ContentType = systemContentFiltered[0].ContentType;
    }

    var CategoryLabel = "";
    if (systemContentFiltered[0] && systemContentFiltered[0].CategoryLabel) {
      CategoryLabel = systemContentFiltered[0].CategoryLabel;
    }

    var ItemDescription = "";
    if (systemContentFiltered[0] && systemContentFiltered[0].ItemDescription) {
      ItemDescription = systemContentFiltered[0].ItemDescription;
    }

    var DetailTitle = "";
    if (ItemData && ItemData.DetailTitle) {
      DetailTitle = ItemData.DetailTitle;
    }
    if (
      (!ItemData || !ItemData.DetailTitle) &&
      systemContentFiltered[0] &&
      systemContentFiltered[0].DetailTitle
    ) {
      DetailTitle = systemContentFiltered[0].DetailTitle;
    }

    var DetailTextDescribe = "";
    if (ItemData && ItemData.DetailTextDescribe) {
      DetailTextDescribe = ItemData.DetailTextDescribe;
    }
    if (!ItemData) {
      DetailTextDescribe = systemContentFiltered[0].DetailTextDescribe;
    }

    var DetailTextLocate = "";
    if (ItemData && ItemData.DetailTextLocate) {
      DetailTextLocate = ItemData.DetailTextLocate;
    }
    if (!ItemData) {
      DetailTextLocate = systemContentFiltered[0].DetailTextLocate;
    }

    var DetailTextOperate = "";
    if (ItemData && ItemData.DetailTextOperate) {
      DetailTextOperate = ItemData.DetailTextOperate;
    }
    if (!ItemData) {
      DetailTextOperate = systemContentFiltered[0].DetailTextOperate;
    }

    var DetailText = "";
    if (ItemData && ItemData.DetailText) {
      DetailText = ItemData.DetailText;
    }
    if (ItemData && !ItemData.DetailText) {
      const detailTextDescribeString =
        ItemData.DetailTextDescribe &&
        !ItemData.DetailTextLocate &&
        !ItemData.DetailTextOperate
          ? `<p>${DetailTextDescribe}</p>`
          : ItemData.DetailTextDescribe &&
            (ItemData.DetailTextLocate || ItemData.DetailTextOperate)
          ? `<p>${DetailTextDescribe}</p><p><br></p>`
          : "";
      const detailTextLocateString =
        ItemData.DetailTextLocate && !ItemData.DetailTextOperate
          ? `<p>${DetailTextLocate}</p>`
          : ItemData.DetailTextLocate && ItemData.DetailTextOperate
          ? `<p>${DetailTextLocate}</p><p><br></p>`
          : "";
      const detailTextOperateString = ItemData.DetailTextOperate
        ? `<p>${DetailTextOperate}</p>`
        : "";
      DetailText = `${detailTextDescribeString}${detailTextLocateString}${detailTextOperateString}`;
    }
    if (!ItemData) {
      DetailText = systemContentFiltered[0].DetailText;
    }

    var DetailImage = null;
    if (ItemData && ItemData.DetailImage && imageFilename !== "null") {
      DetailImage = ItemData.DetailImage;
    }
    if (!ItemData) {
      DetailImage = systemContentFiltered[0].DetailImage;
    }

    var DetailVideo = null;
    if (ItemData && ItemData.DetailVideo && VideoFilename !== "null") {
      DetailVideo = ItemData.DetailVideo;
    }
    if (!ItemData) {
      DetailVideo = systemContentFiltered[0].DetailVideo;
    }

    var DetailDocument = null;
    if (ItemData && ItemData.DetailDocument && documentFilename !== "null") {
      DetailDocument = ItemData.DetailDocument;
    }
    if (!ItemData) {
      DetailDocument = systemContentFiltered[0].DetailDocument;
    }

    var WebLink = "";
    if (ItemData && ItemData.WebLink) {
      WebLink = ItemData.WebLink;
    }
    if (!ItemData) {
      WebLink = systemContentFiltered[0].WebLink;
    }

    var FeaturedContent = false;
    if (ItemData && ItemData.FeaturedContent) {
      FeaturedContent = ItemData.FeaturedContent;
    }
    if (!ItemData) {
      FeaturedContent = systemContentFiltered[0].FeaturedContent;
    }

    var LinkedPropertyList = [];
    if (isComponentShared && ItemData) {
      LinkedPropertyList = ItemData.LinkedProperty;
    }

    var SubContent = [];

    this.state = {
      propertyId: propertyId,
      vanityPropertyId: vanityPropertyId,
      detailLabel: DetailLabel,
      itemUniqueId: ItemUniqueId,
      contentType: ContentType,
      categoryLabel: CategoryLabel,
      itemDescription: ItemDescription,
      detailTitle: DetailTitle,
      detailText: DetailText,
      detailTextDescribe: DetailTextDescribe,
      detailTextLocate: DetailTextLocate,
      detailTextOperate: DetailTextOperate,
      webLink: WebLink,
      detailImage: DetailImage,
      detailVideo: DetailVideo,
      detailDocument: DetailDocument,
      featuredContent: FeaturedContent,
      subContent: SubContent,
      detailTitlePrevState: DetailTitle,
      detailTextPrevState: DetailText,
      detailTextDescribePrevState: DetailTextDescribe,
      detailTextLocatePrevState: DetailTextLocate,
      detailTextOperatePrevState: DetailTextOperate,
      webLinkPrevState: WebLink,
      detailImagePrevState: DetailImage,
      detailVideoPrevState: DetailVideo,
      detailDocumentPrevState: DetailDocument,
      subContentPrevState: SubContent,
      linkedProperties: LinkedPropertyList,
      selectedImageFile: null,
      selectedVideoFile: null,
      selectedDocumentFile: null,
      emptyImageField: {},
      emptyVideoField: {},
      emptyDocumentField: {},
      imageInputKey: null,
      videoInputKey: null,
      documentInputKey: null,
      showImage: true,
      showVideo: true,
      showDocument: true,
      displaySaveButton: true,
      imagePreviewLoading: true,
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onImageUploaderChange = this.onImageUploaderChange.bind(this);
    this.onImageDelete = this.onImageDelete.bind(this);
    this.onVideoUploaderChange = this.onVideoUploaderChange.bind(this);
    this.onVideoDelete = this.onVideoDelete.bind(this);
    this.onDocumentUploaderChange = this.onDocumentUploaderChange.bind(this);
    this.onDocumentDelete = this.onDocumentDelete.bind(this);
  }

  onTextChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onHtmlTextChange = (content) => {
    this.setState({
      detailText: content,
    });
  };

  onRemoveContentClick = (removeIndex) => {
    // console.log("removeIndex", removeIndex)
    let removalStateUpdate = [];
    let subContentCopy = this.state.subContent;
    // If it is existing content, keep it in subContent list but change action to "delete"
    // 2. Make a shallow copy of the item you want to mutate
    let item = subContentCopy[removeIndex];
    // 3. Replace the property you're interested in
    item.action = "delete";
    // item.fileDeleteUrl = "nnn";
    // 4. Put it back into our array
    subContentCopy[removeIndex] = item;
    // 5. Set the state to our new copy
    removalStateUpdate = subContentCopy;
    // }
    this.setState({
      subContent: removalStateUpdate,
    });
  };

  onImagePreviewLoad = () => {
    this.setState({
      imagePreviewLoading: false,
    });
  };

  onImageUploaderChange = (e) => {
    //  let imageFile = e.target.files[0];
    //  this.setState({
    //    imageFilename: imageFile.name
    //  });
    //  const imageFileReader = new FileReader();
    //  imageFileReader.onload = this.handleImageReaderLoaded.bind(this);
    //  imageFileReader.readAsBinaryString(imageFile);

    //  e.persist();
    // const { ItemLabel, ItemId } = this.props;
    const { isComponentShared, SharedContentProfileId } = this.props;
    const FileFolder = isComponentShared
      ? "shared-content"
      : "property-content";
    const ItemLabel = this.state.detailLabel;
    const ItemId = isComponentShared
      ? SharedContentProfileId
      : this.state.propertyId;
    let imageFile = e.target.files[0];
    this.setState({
      imageFilename: `${FileFolder}/${ItemId}/${ItemLabel}-image_${imageFile.name}`,
    });
    const reader = new FileReader();
    reader.onload = this.handleImageReaderLoaded.bind(this);
    //  reader.readAsBinaryString(file); // Bubble API
    reader.readAsArrayBuffer(imageFile);
    // console.log("file", file);
  };

  handleImageReaderLoaded = (readerEvt) => {
    //  let binaryImageString = readerEvt.target.result;
    //  let imageFilename = this.state.imageFilename;
    //  this.setState({
    //    selectedImageFile: btoa(binaryImageString),
    //   showImage: true,
    //   detailImage: {
    //    filename: imageFilename,
    //     contents: btoa(binaryImageString),
    //     private: false
    //  }
    // });
    const { userId } = this.props;
    let imageReaderFile = readerEvt.target.result;
    let imageFilename = this.state.imageFilename;
    //  console.log("binaryString", binaryString);

    var imageBlob = new Blob([imageReaderFile], { type: "image/jpeg" });
    var imageUrlCreator = window.URL || window.webkitURL;
    var imageUrl = imageUrlCreator.createObjectURL(imageBlob);

    this.setState({
      selectedImageFile: imageReaderFile,
      showImage: true,
      imageFilePreview: imageUrl,
      // selectedFile: btoa(binaryString), // Bubble API
      detailImage: `${cdnRootUrl}/user/${userId}/${imageFilename}`,
      //{  // Bubble API
      //   filename: fileName, // Bubble API
      //   contents: btoa(binaryString), // Bubble API
      //   private: false // Bubble API
      // }
    });
  };

  onImageDelete = (e) => {
    e.preventDefault();
    let randomImageString = Math.random().toString(36);
    this.setState({
      imageFilename: "null",
      detailImage: null,
      showImage: false,
      selectedImageFile: null,
      imageInputKey: randomImageString,
    });
  };

  onVideoUploaderChange = (e) => {
    // let videoFile = e.target.files[0];
    //  this.setState({
    //   videoFileName: videoFile.name
    // });
    // const videoFileReader = new FileReader();
    // videoFileReader.onload = this.handleVideoReaderLoaded.bind(this);
    //  videoFileReader.readAsBinaryString(videoFile);
    const { isComponentShared, SharedContentProfileId } = this.props;
    const FileFolder = isComponentShared
      ? "shared-content"
      : "property-content";
    const ItemLabel = this.state.detailLabel;
    const ItemId = isComponentShared
      ? SharedContentProfileId
      : this.state.propertyId;
    let videoFile = e.target.files[0];
    this.setState({
      videoFilename: `${FileFolder}/${ItemId}/${ItemLabel}-video_${videoFile.name}`,
    });
    const reader = new FileReader();
    reader.onload = this.handleVideoReaderLoaded.bind(this);
    //  reader.readAsBinaryString(file); // Bubble API
    reader.readAsArrayBuffer(videoFile);
    // console.log("file", file);
  };

  handleVideoReaderLoaded = (readerEvt) => {
    //  let binaryVideoString = readerEvt.target.result;
    //  let videoFileName = this.state.videoFileName;
    //  this.setState({
    //    selectedVideoFile: btoa(binaryVideoString),
    //    showVideo: true,
    //    detailVideo: {
    //     filename: videoFileName,
    //     contents: btoa(binaryVideoString),
    //    private: false
    //  }
    //  });
    const { userId } = this.props;
    let videoReaderFile = readerEvt.target.result;
    let videoFilename = this.state.videoFilename;
    var videoBlob = new Blob([videoReaderFile], { type: "image/jpeg" });
    var videoUrlCreator = window.URL || window.webkitURL;
    var videoUrl = videoUrlCreator.createObjectURL(videoBlob);

    this.setState({
      selectedVideoFile: videoReaderFile,
      showVideo: true,
      videoFilePreview: videoUrl,
      detailVideo: `${cdnRootUrl}/user/${userId}/${videoFilename}`,
      // selectedFile: btoa(binaryString), // Bubble API
      //{  // Bubble API
      //   filename: fileName, // Bubble API
      //   contents: btoa(binaryString), // Bubble API
      //   private: false // Bubble API
      // }
    });
  };

  onVideoDelete = (e) => {
    e.preventDefault();
    let randomVideoString = Math.random().toString(36);
    this.setState({
      videoFileName: "null",
      detailVideo: null,
      showVideo: false,
      selectedVideoFile: null,
      videoInputKey: randomVideoString,
    });
  };

  onDocumentUploaderChange = (e) => {
    //   let documentFile = e.target.files[0];
    //  this.setState({
    //    documentFilename: documentFile.name
    //  });
    //  const documentFileReader = new FileReader();
    //  documentFileReader.onload = this.handleDocumentReaderLoaded.bind(this);
    //  documentFileReader.readAsBinaryString(documentFile);
    const { isComponentShared, SharedContentProfileId } = this.props;
    const FileFolder = isComponentShared
      ? "shared-content"
      : "property-content";
    const ItemLabel = this.state.detailLabel;
    const ItemId = isComponentShared
      ? SharedContentProfileId
      : this.state.propertyId;
    let documentFile = e.target.files[0];
    this.setState({
      documentFilename: `${FileFolder}/${ItemId}/${ItemLabel}-document_${documentFile.name}`,
    });
    const reader = new FileReader();
    reader.onload = this.handleDocumentReaderLoaded.bind(this);
    //  reader.readAsBinaryString(file); // Bubble API
    reader.readAsArrayBuffer(documentFile);
    // console.log("file", file);
  };

  handleDocumentReaderLoaded = (readerEvt) => {
    //  let binaryDocumentString = readerEvt.target.result;
    //   let documentFilename = this.state.documentFilename;
    //  this.setState({
    //    selectedDocumentFile: btoa(binaryDocumentString),
    //   showDocument: true,
    //   detailDocument: {
    //     filename: documentFilename,
    //     contents: btoa(binaryDocumentString),
    //     private: false
    //   }
    // });
    const { userId } = this.props;
    let documentReaderFile = readerEvt.target.result;
    let documentFilename = this.state.documentFilename;
    var documentBlob = new Blob([documentReaderFile], {
      type: "application/pdf",
    });
    var documentUrlCreator = window.URL || window.webkitURL;
    var documentUrl = documentUrlCreator.createObjectURL(documentBlob);

    this.setState({
      selectedDocumentFile: documentReaderFile,
      showDocument: true,
      documentFilePreview: documentUrl,
      detailDocument: `${cdnRootUrl}/user/${userId}/${documentFilename}`,
      // selectedFile: btoa(binaryString), // Bubble API
      //{  // Bubble API
      //   filename: fileName, // Bubble API
      //   contents: btoa(binaryString), // Bubble API
      //   private: false // Bubble API
      // }
    });
  };

  onDocumentDelete = (e) => {
    e.preventDefault();
    let randomDocumentString = Math.random().toString(36);
    this.setState({
      documentFilename: "null",
      detailDocument: null,
      showDocument: false,
      selectedDocumentFile: null,
      imageInputKey: randomDocumentString,
    });
  };

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked;
    this.setState({
      featuredContent: value,
    });
  };

  onEdit = (e) => {
    let randomImageString = Math.random().toString(36);
    let randomVideoString = Math.random().toString(36);
    let randomDocumentString = Math.random().toString(36);
    let randomSubContentString = Math.random().toString(36);
    e.preventDefault();
    this.props.setSaveStatus(`${this.state.itemUniqueId}-editing`);
    this.setState({
      detailTitle: this.state.detailTitlePrevState,
      detailText: this.state.detailTextPrevState,
      detailTextDescribe: this.state.detailTextDescribePrevState,
      detailTextLocate: this.state.detailTextLocatePrevState,
      detailTextOperate: this.state.detailTextOperatePrevState,
      webLink: this.state.webLinkPrevState,
      detailImage: this.state.detailImagePrevState,
      detailVideo: this.state.detailVideoPrevState,
      detailDocument: this.state.detailDocumentPrevState,
      featuredContent: this.state.featuredContentPrevState,
      selectedImageFile: null,
      selectedVideoFile: null,
      selectedDocumentFile: null,
      showImage: true,
      showVideo: true,
      showDocument: true,
      imageInputKey: randomImageString,
      videoInputKey: randomVideoString,
      documentInputString: randomDocumentString,
      subContent: this.state.subContentPrevState,
      subContentString: randomSubContentString,
      displaySaveButton: true,
    });
  };

  onCancel = (e) => {
    e.preventDefault();
    let randomImageString = Math.random().toString(36);
    let randomVideoString = Math.random().toString(36);
    let randomDocumentString = Math.random().toString(36);
    let randomSubContentString = Math.random().toString(36);
    this.props.setSaveStatus(`${this.state.itemUniqueId}-idle`);
    this.setState({
      detailTitle: this.state.detailTitlePrevState,
      detailText: this.state.detailTextPrevState,
      detailTextDescribe: this.state.detailTextDescribePrevState,
      detailTextLocate: this.state.detailTextLocatePrevState,
      detailTextOperate: this.state.detailTextOperatePrevState,
      webLink: this.state.webLinkPrevState,
      detailImage: this.state.detailImagePrevState,
      detailVideo: this.state.detailVideoPrevState,
      detailDocument: this.state.detailDocumentPrevState,
      featuredContent: this.state.featuredContentPrevState,
      selectedImageFile: null,
      selectedVideoFile: null,
      selectedDocumentFile: null,
      showImage: true,
      showVideo: true,
      showDocument: true,
      imageInputKey: randomImageString,
      videoInputKey: randomVideoString,
      documentInputString: randomDocumentString,
      subContent: this.state.subContentPrevState,
      subContentString: randomSubContentString,
    });
  };

  onSubContentStateChange = (content) => {
    this.setState({
      subContent: content,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      userId,
      DetailLabel,
      IsCustomLabel,
      customContent,
      PassedCategoryLabel,
      ItemUniqueId,
      InfoType,
      SharedContentProfileId,
      isComponentShared,
      sharedContent,
    } = this.props;

    // Filter custom content by property id
    const customContentFilterOne = isComponentShared
      ? null
      : customContent.map((item) => {
          return {
            ...item,
            LinkedProperty: item.LinkedProperty.filter(
              (subItem) => subItem === this.state.propertyId
            ),
          };
        });

    const customContentFilterTwo = isComponentShared
      ? sharedContent
      : customContentFilterOne.filter((item) => {
          return item.LinkedProperty[0] === this.state.propertyId;
        });

    // Lookup and set ItemData
    const itemDataFilteredByLabel = customContentFilterTwo.filter((Item) => {
      return Item.DetailLabel === DetailLabel;
    });

    const itemDataFilteredById = customContentFilterTwo.filter((Item) => {
      return Item._id === ItemUniqueId;
    });

    var ItemData = null;
    if (InfoType === "system") {
      ItemData = itemDataFilteredByLabel[0];
    }
    if (InfoType === "custom") {
      ItemData = itemDataFilteredById[0];
    }

    var ContentId = "";
    if (ItemData && ItemData._id) {
      ContentId = ItemData._id;
    }

    // Creates new DetailLabel from DetailTitle and checks against user data for duplicate
    // Starts here

    var newDetailLabel = DetailLabel;
    if (DetailLabel === "custom" || IsCustomLabel) {
      newDetailLabel = this.state.detailTitle
        .replace(/[^a-zA-Z0-9]/g, "-")
        .replace(/----/g, "-")
        .replace(/---/g, "-")
        .replace(/--/g, "-")
        .toLowerCase();

      //console.log("newDetailLabel", newDetailLabel);

      do {
        const customContentFilteredByPropertyId = isComponentShared
          ? []
          : customContentFilterOne.filter((item) => {
              return item.LinkedProperty[0] === this.state.propertyId;
            });

        const mergedCustomContent = isComponentShared
          ? sharedContent
          : !isComponentShared && sharedContent
          ? customContentFilteredByPropertyId.concat(sharedContent)
          : customContentFilteredByPropertyId;
        //console.log("mergedCustomContent", mergedCustomContent)

        var labelDuplicateFound = mergedCustomContent.filter((item) => {
          return item.DetailLabel === newDetailLabel;
        });
        //console.log("labelDuplicateFound", labelDuplicateFound)

        if (labelDuplicateFound.length > 0) {
          var duplicateDetailLabel = labelDuplicateFound[0].DetailLabel;
          var lastDashInLabel = duplicateDetailLabel.lastIndexOf("-");
          var labelNumber =
            lastDashInLabel !== -1
              ? duplicateDetailLabel.substring(lastDashInLabel + 1)
              : "";
          //console.log("labelNumber", labelNumber)

          var labelStringToNumber = Number(labelNumber);
          //console.log("labelStringToNumber", labelStringToNumber);

          var isLastStringNumber = isNaN(labelStringToNumber);
          //console.log("isLastStringNumber", isLastStringNumber);

          var numberToAppend = labelStringToNumber + 1;
          var numberToString = numberToAppend.toString();

          var baseString = duplicateDetailLabel.substr(
            0,
            duplicateDetailLabel.lastIndexOf("-")
          );

          var finalNewLabel = "";
          if (!isLastStringNumber) {
            finalNewLabel = baseString + "-" + numberToString;
          }
          if (isLastStringNumber) {
            finalNewLabel = duplicateDetailLabel + "-1";
          }

          //console.log("finalNewLabel", finalNewLabel)

          newDetailLabel = finalNewLabel;
        }
      } while (labelDuplicateFound.length > 0);
    }

    // Ends here

    var isCustomLabel = IsCustomLabel;
    if (DetailLabel === "custom") {
      isCustomLabel = true;
    }

    var newCategoryLabel = this.state.categoryLabel;
    if (this.state.categoryLabel === "*") {
      newCategoryLabel = PassedCategoryLabel;
    }

    var ContentVersion = 0;
    if (ItemData && ItemData.ContentVersion) {
      ContentVersion = ItemData.ContentVersion;
    }
    if (!ItemData) {
      ContentVersion = 0;
    }

    var mainContentInfo = {
      DetailTitle: this.state.detailTitle,
      DetailText: this.state.detailText,
      DetailTextDescribe: this.state.detailTextDescribe,
      DetailTextLocate: this.state.detailTextLocate,
      DetailTextOperate: this.state.detailTextOperate,
      WebLink: this.state.webLink,
      FeaturedContent: this.state.featuredContent,
      DetailLabel: newDetailLabel,
      ContentVersion: ContentVersion + 1,
    };

    var fileContentInfo = {};

    const cdnRootString = `${cdnRootUrl}/`;
    const cdnImageStorageString =
      ItemData && ItemData.DetailImage
        ? `${ItemData.DetailImage.slice(
            ItemData.DetailImage.indexOf(cdnRootString) + cdnRootString.length
          )}`
        : null;

    const deleteImageFileString =
      ItemData && ItemData.DetailImage
        ? ItemData.DetailImage.split("/")[0] === "https:"
          ? cdnImageStorageString
          : `https:${ItemData.DetailImage}`
        : null;

    const cdnVideoStorageString =
      ItemData && ItemData.DetailVideo
        ? `${ItemData.DetailVideo.slice(
            ItemData.DetailVideo.indexOf(cdnRootString) + cdnRootString.length
          )}`
        : null;

    const deleteVideoFileString =
      ItemData && ItemData.DetailVideo
        ? ItemData.DetailVideo.split("/")[0] === "https:"
          ? cdnVideoStorageString
          : `https:${ItemData.DetailVideo}`
        : null;

    const cdnDocumentStorageString =
      ItemData && ItemData.DetailDocument
        ? `${ItemData.DetailDocument.slice(
            ItemData.DetailDocument.indexOf(cdnRootString) +
              cdnRootString.length
          )}`
        : null;

    const deleteDocumentFileString =
      ItemData && ItemData.DetailDocument
        ? ItemData.DetailDocument.split("/")[0] === "https:"
          ? cdnDocumentStorageString
          : `https:${ItemData.DetailDocument}`
        : null;

    if (
      this.state.detailImage !== this.state.detailImagePrevState &&
      this.state.detailVideo === this.state.detailVideoPrevState &&
      this.state.detailDocument === this.state.detailDocumentPrevState
    ) {
      this.setState({
        imagePreviewLoading: true,
      });
      fileContentInfo = {
        DetailImage: this.state.detailImage,
      };
      if (ItemData && ItemData.DetailImage) {
        this.props.deleteUploadedFile(deleteImageFileString);
      }
    }

    if (
      this.state.detailImage === this.state.detailImagePrevState &&
      this.state.detailVideo !== this.state.detailVideoPrevState &&
      this.state.detailDocument === this.state.detailDocumentPrevState
    ) {
      fileContentInfo = {
        DetailVideo: this.state.detailVideo,
      };
      if (ItemData && ItemData.DetailVideo) {
        this.props.deleteUploadedFile(deleteVideoFileString);
      }
    }

    if (
      this.state.detailImage === this.state.detailImagePrevState &&
      this.state.detailVideo === this.state.detailVideoPrevState &&
      this.state.detailDocument !== this.state.detailDocumentPrevState
    ) {
      fileContentInfo = {
        DetailDocument: this.state.detailDocument,
      };
      if (ItemData && ItemData.DetailDocument) {
        this.props.deleteUploadedFile(deleteDocumentFileString);
      }
    }

    if (
      this.state.detailImage !== this.state.detailImagePrevState &&
      this.state.detailVideo !== this.state.detailVideoPrevState &&
      this.state.detailDocument !== this.state.detailDocumentPrevState
    ) {
      this.setState({
        imagePreviewLoading: true,
      });
      fileContentInfo = {
        DetailImage: this.state.detailImage,
        DetailVideo: this.state.detailVideo,
        DetailDocument: this.state.detailDocument,
      };
      if (ItemData && ItemData.DetailImage) {
        this.props.deleteUploadedFile(deleteImageFileString);
      }
      if (ItemData && ItemData.DetailVideo) {
        this.props.deleteUploadedFile(deleteVideoFileString);
      }
      if (ItemData && ItemData.DetailDocument) {
        this.props.deleteUploadedFile(deleteDocumentFileString);
      }
    }

    if (
      this.state.detailImage !== this.state.detailImagePrevState &&
      this.state.detailVideo !== this.state.detailVideoPrevState &&
      this.state.detailDocument === this.state.detailDocumentPrevState
    ) {
      this.setState({
        imagePreviewLoading: true,
      });
      fileContentInfo = {
        DetailImage: this.state.detailImage,
        DetailVideo: this.state.detailVideo,
      };
      if (ItemData && ItemData.DetailImage) {
        this.props.deleteUploadedFile(deleteImageFileString);
      }
      if (ItemData && ItemData.DetailVideo) {
        this.props.deleteUploadedFile(deleteVideoFileString);
      }
    }

    if (
      this.state.detailImage !== this.state.detailImagePrevState &&
      this.state.detailVideo === this.state.detailVideoPrevState &&
      this.state.detailDocument !== this.state.detailDocumentPrevState
    ) {
      this.setState({
        imagePreviewLoading: true,
      });
      fileContentInfo = {
        DetailImage: this.state.detailImage,
        DetailDocument: this.state.detailDocument,
      };
      if (ItemData && ItemData.DetailImage) {
        this.props.deleteUploadedFile(deleteImageFileString);
      }
      if (ItemData && ItemData.DetailDocument) {
        this.props.deleteUploadedFile(deleteDocumentFileString);
      }
    }

    if (
      this.state.detailImage === this.state.detailImagePrevState &&
      this.state.detailVideo !== this.state.detailVideoPrevState &&
      this.state.detailDocument !== this.state.detailDocumentPrevState
    ) {
      fileContentInfo = {
        DetailVideo: this.state.detailVideo,
        DetailDocument: this.state.detailDocument,
      };
      if (ItemData && ItemData.DetailVideo) {
        this.props.deleteUploadedFile(deleteVideoFileString);
      }
      if (ItemData && ItemData.DetailDocument) {
        this.props.deleteUploadedFile(deleteDocumentFileString);
      }
    }

    var updateContentInfo = {
      ...mainContentInfo,
      ...fileContentInfo,
    };

    var createContentInfo = {
      DetailTitle: this.state.detailTitle,
      DetailText: this.state.detailText,
      DetailTextDescribe: this.state.detailTextDescribe,
      DetailTextLocate: this.state.detailTextLocate,
      DetailTextOperate: this.state.detailTextOperate,
      WebLink: this.state.webLink,
      DetailImage: this.state.detailImage,
      DetailVideo: this.state.detailVideo,
      DetailDocument: this.state.detailDocument,
      FeaturedContent: this.state.featuredContent,
      ContentVersion: 1,
      LinkedProperty: isComponentShared ? null : [this.state.propertyId],
      LinkedSharedContentProfile: isComponentShared
        ? SharedContentProfileId
        : null,
      LinkedMasterUser: userId,
      ContentType: this.state.contentType,
      CategoryLabel: newCategoryLabel,
      DetailLabel: newDetailLabel,
      IsCustomLabel: isCustomLabel,
    };

    if (this.state.detailTitle !== "") {
      const linkedProperties = isComponentShared
        ? this.state.linkedProperties
        : [this.state.propertyId];

      if (ContentId) {
        this.props.updateContent(
          updateContentInfo,
          ContentId,
          ItemUniqueId,
          this.state.propertyId,
          SharedContentProfileId,
          userId,
          this.state.imageFilename,
          this.state.selectedImageFile,
          this.state.videoFilename,
          this.state.selectedVideoFile,
          this.state.documentFilename,
          this.state.selectedDocumentFile,
          this.state.subContent,
          linkedProperties
        );
      }

      if (!ContentId) {
        this.props.createContent(
          createContentInfo,
          ItemUniqueId,
          this.state.propertyId,
          SharedContentProfileId,
          userId,
          this.state.imageFilename,
          this.state.selectedImageFile,
          this.state.videoFilename,
          this.state.selectedVideoFile,
          this.state.documentFilename,
          this.state.selectedDocumentFile,
          this.state.subContent
        );
      }
    }

    this.setState({
      detailTitlePrevState: this.state.detailTitle,
      detailTextPrevState: this.state.detailText,
      detailTextDescribePrevState: this.state.detailTextDescribe,
      detailTextLocatePrevState: this.state.detailTextLocate,
      detailTextOperatePrevState: this.state.detailTextOperate,
      webLinkPrevState: this.state.webLink,
      detailImagePrevState: this.state.detailImage,
      detailVideoPrevState: this.state.detailVideo,
      detailDocumentPrevState: this.state.detailDocument,
      featuredContentPrevState: this.state.featuredContent,
    });
  };

  handleStateChange = (payload) => {
    this.setState({
      displaySaveButton: payload,
    });
  };

  render() {
    const {
      systemContent,
      customContent,
      DetailLabel,
      SaveStatus,
      InfoType,
      ItemUniqueId,
      IsCustomLabel,
      isComponentShared,
      SharedContentProfileId,
      sharedContent,
      PropertyId,
      userId,
    } = this.props;
    // console.log("newDetailLabel", this.state.detailLabel);
    var propertyId = "";
    if (!isComponentShared && PropertyId) {
      propertyId = PropertyId;
    }

    // Use Detail label to lookup system content
    const systemContentFilteredOne = systemContent.filter((DisplayContent) => {
      return DisplayContent.DetailLabel === DetailLabel;
    });

    var systemContentFiltered = null;
    if (systemContentFilteredOne[0]) {
      systemContentFiltered = systemContent.filter((DisplayContent) => {
        return DisplayContent.DetailLabel === DetailLabel;
      });
    }

    // Filter custom content by propertyId
    const customContentFilterOne = isComponentShared
      ? null
      : customContent.map((element) => {
          return {
            ...element,
            LinkedProperty: element.LinkedProperty.filter(
              (subElement) => subElement === propertyId
            ),
          };
        });

    const customContentFilterTwo = isComponentShared
      ? sharedContent
      : customContentFilterOne.filter((Item) => {
          return Item.LinkedProperty[0] === propertyId;
        });

    // Lookup and set ItemData
    const itemDataFilteredByLabel = customContentFilterTwo
      ? customContentFilterTwo.filter((Item) => {
          return Item.DetailLabel === DetailLabel;
        })
      : null;

    const itemDataFilteredById = customContentFilterTwo
      ? customContentFilterTwo.filter((Item) => {
          return Item._id === ItemUniqueId;
        })
      : null;

    var ItemData = null;
    if (InfoType === "system" && itemDataFilteredByLabel) {
      ItemData = itemDataFilteredByLabel[0];
    }
    if (InfoType === "custom" && itemDataFilteredById) {
      ItemData = itemDataFilteredById[0];
    }

    var ContentId = "";
    if (ItemData && ItemData._id) {
      ContentId = ItemData._id;
    }

    // Set Filenames so "null"s can be filtered out
    const imageFilename =
      ItemData && ItemData.DetailImage
        ? ItemData.DetailImage.substring(
            ItemData.DetailImage.lastIndexOf("/") + 1
          )
        : "";

    const VideoFilename =
      ItemData && ItemData.DetailVideo
        ? ItemData.DetailVideo.substring(
            ItemData.DetailVideo.lastIndexOf("/") + 1
          )
        : "";

    const documentFilename =
      ItemData && ItemData.DetailDocument
        ? ItemData.DetailDocument.substring(
            ItemData.DetailDocument.lastIndexOf("/") + 1
          )
        : "";

    var DetailText = "";
    if (ItemData && ItemData.DetailText) {
      DetailText = ItemData.DetailText;
    }
    if (!ItemData) {
      DetailText = systemContentFiltered[0].DetailText;
    }

    var detailTextPreviewString = "";

    if (ItemData && ItemData.DetailText) {
      const brsReplaced = DetailText ? DetailText.replace(/<br>/g, " ") : "";
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = brsReplaced;
      detailTextPreviewString = tempDiv.textContent || tempDiv.innerText || "";
    }

    var DetailTextDescribe = "";
    if (ItemData && ItemData.DetailTextDescribe) {
      DetailTextDescribe = ItemData.DetailTextDescribe;
    }
    if (!ItemData) {
      DetailTextDescribe = systemContentFiltered[0].DetailTextDescribe;
    }

    var DetailTextLocate = "";
    if (ItemData && ItemData.DetailTextLocate) {
      DetailTextLocate = ItemData.DetailTextLocate;
    }
    if (!ItemData) {
      DetailTextLocate = systemContentFiltered[0].DetailTextLocate;
    }

    var DetailTextOperate = "";
    if (ItemData && ItemData.DetailTextOperate) {
      DetailTextOperate = ItemData.DetailTextOperate;
    }
    if (!ItemData) {
      DetailTextOperate = systemContentFiltered[0].DetailTextOperate;
    }

    var DetailTextPreview = "";
    if (detailTextPreviewString) {
      DetailTextPreview = detailTextPreviewString;
    }
    if (!detailTextPreviewString) {
      const detailTextDescribeString = DetailTextDescribe
        ? `${DetailTextDescribe} `
        : "";
      const detailTextLocateString = DetailTextLocate
        ? `${DetailTextLocate} `
        : "";
      const detailTextOperateString = DetailTextOperate
        ? `${DetailTextOperate} `
        : "";
      DetailTextPreview = `${detailTextDescribeString}${detailTextLocateString}${detailTextOperateString}`;
    }

    var DetailImage = null;
    if (ItemData && ItemData.DetailImage && imageFilename !== "null") {
      DetailImage = ItemData.DetailImage;
    }
    if (!ItemData) {
      DetailImage = systemContentFiltered[0].DetailImage;
    }

    var DetailVideo = null;
    if (ItemData && ItemData.DetailVideo && VideoFilename !== "null") {
      DetailVideo = ItemData.DetailVideo;
    }
    if (!ItemData) {
      DetailVideo = systemContentFiltered[0].DetailVideo;
    }

    var DetailDocument = null;
    if (ItemData && ItemData.DetailDocument && documentFilename !== "null") {
      DetailDocument = ItemData.DetailDocument;
    }
    if (!ItemData) {
      DetailDocument = systemContentFiltered[0].DetailDocument;
    }

    var WebLink = "";
    if (ItemData && ItemData.WebLink) {
      WebLink = ItemData.WebLink;
    }

    // Use Detail label to lookup system content
    const systemCustomSettingsOne = systemContent.filter((DisplayContent) => {
      return (
        DisplayContent.DetailLabel === "custom"
        //  && DisplayContent.CategoryLabel === this.state.categoryLabel
      );
    });

    var systemCustomSettings = null;
    if (systemCustomSettingsOne[0]) {
      systemCustomSettings = systemCustomSettingsOne;
    }

    var ItemDescription = "";
    if (
      IsCustomLabel &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemDescription
    ) {
      ItemDescription = systemCustomSettings[0].ItemDescription;
    }
    if (
      !IsCustomLabel &&
      systemContentFiltered &&
      systemContentFiltered[0].ItemDescription
    ) {
      ItemDescription = systemContentFiltered[0].ItemDescription;
    }
    if (
      !IsCustomLabel &&
      (!systemContentFiltered || !systemContentFiltered[0].ItemDescription) &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemDescription
    ) {
      ItemDescription = systemCustomSettings[0].ItemDescription;
    }

    var ItemTitleLabel = "";
    if (
      IsCustomLabel &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemTitleLabel
    ) {
      ItemTitleLabel = systemCustomSettings[0].ItemTitleLabel;
    }
    if (
      !IsCustomLabel &&
      systemContentFiltered &&
      systemContentFiltered[0].ItemTitleLabel
    ) {
      ItemTitleLabel = systemContentFiltered[0].ItemTitleLabel;
    }
    if (
      !IsCustomLabel &&
      (!systemContentFiltered || !systemContentFiltered[0].ItemTitleLabel) &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemTitleLabel
    ) {
      ItemTitleLabel = systemCustomSettings[0].ItemTitleLabel;
    }

    var ItemWebLinkLabel = "";
    if (
      IsCustomLabel &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemWebLinkLabel
    ) {
      ItemWebLinkLabel = systemCustomSettings[0].ItemWebLinkLabel;
    }
    if (
      !IsCustomLabel &&
      systemContentFiltered &&
      systemContentFiltered[0].ItemWebLinkLabel
    ) {
      ItemWebLinkLabel = systemContentFiltered[0].ItemWebLinkLabel;
    }
    if (
      !IsCustomLabel &&
      (!systemContentFiltered || !systemContentFiltered[0].ItemWebLinkLabel) &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemWebLinkLabel
    ) {
      ItemWebLinkLabel = systemCustomSettings[0].ItemWebLinkLabel;
    }

    var ItemFeaturedContentLabel = "";
    if (
      IsCustomLabel &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemFeaturedContentLabel
    ) {
      ItemFeaturedContentLabel =
        systemCustomSettings[0].ItemFeaturedContentLabel;
    }
    if (
      !IsCustomLabel &&
      systemContentFiltered &&
      systemContentFiltered[0].ItemFeaturedContentLabel
    ) {
      ItemFeaturedContentLabel =
        systemContentFiltered[0].ItemFeaturedContentLabel;
    }
    if (
      !IsCustomLabel &&
      (!systemContentFiltered ||
        !systemContentFiltered[0].ItemFeaturedContentLabel) &&
      systemCustomSettings &&
      systemCustomSettings[0].ItemFeaturedContentLabel
    ) {
      ItemFeaturedContentLabel =
        systemCustomSettings[0].ItemFeaturedContentLabel;
    }

    var ItemIsRequired = false;
    if (IsCustomLabel) {
      ItemIsRequired = systemCustomSettings[0].ItemIsRequired;
    }
    if (!IsCustomLabel && systemContentFiltered) {
      ItemIsRequired = systemContentFiltered[0].ItemIsRequired;
    }
    if (!IsCustomLabel && !systemContentFiltered && systemCustomSettings) {
      ItemIsRequired = systemCustomSettings[0].ItemIsRequired;
    }

    var ItemShowTitle = false;
    if (IsCustomLabel) {
      ItemShowTitle = systemCustomSettings[0].ItemShowTitle;
    }
    if (!IsCustomLabel && systemContentFiltered) {
      ItemShowTitle = systemContentFiltered[0].ItemShowTitle;
    }
    if (!IsCustomLabel && !systemContentFiltered && systemCustomSettings) {
      ItemShowTitle = systemCustomSettings[0].ItemShowTitle;
    }

    var ItemShowWebLink = false;
    if (IsCustomLabel) {
      ItemShowWebLink = systemCustomSettings[0].ItemShowWebLink;
    }
    if (!IsCustomLabel && systemContentFiltered) {
      ItemShowWebLink = systemContentFiltered[0].ItemShowWebLink;
    }
    if (!IsCustomLabel && !systemContentFiltered && systemCustomSettings) {
      ItemShowWebLink = systemCustomSettings[0].ItemShowWebLink;
    }

    var ItemShowMedia = false;
    if (IsCustomLabel) {
      ItemShowMedia = systemCustomSettings[0].ItemShowMedia;
    }
    if (!IsCustomLabel && systemContentFiltered) {
      ItemShowMedia = systemContentFiltered[0].ItemShowMedia;
    }
    if (!IsCustomLabel && !systemContentFiltered && systemCustomSettings) {
      ItemShowMedia = systemCustomSettings[0].ItemShowMedia;
    }

    var ItemShowFeaturedContent = false;
    if (IsCustomLabel) {
      ItemShowFeaturedContent = systemCustomSettings[0].ItemShowFeaturedContent;
    }
    if (!IsCustomLabel && systemContentFiltered) {
      ItemShowFeaturedContent =
        systemContentFiltered[0].ItemShowFeaturedContent;
    }
    if (!IsCustomLabel && !systemContentFiltered && systemCustomSettings) {
      ItemShowFeaturedContent = systemCustomSettings[0].ItemShowFeaturedContent;
    }

    var ContentVersion = 0;
    if (ItemData && ItemData.ContentVersion) {
      ContentVersion = ItemData.ContentVersion;
    }
    if (!ItemData) {
      ContentVersion = 0;
    }

    const systemPlaceholderContent =
      ContentVersion && ContentVersion > 0 ? false : true;

    return (
      <>
        <div className="infoitem-container">
          <div className="infoitem-heading-and-button-container">
            <div className="infoitem-heading">
              <InfoItemCheckMark
                IsChecked={
                  !systemPlaceholderContent &&
                  (DetailText ||
                    DetailTextDescribe ||
                    DetailTextLocate ||
                    DetailTextOperate ||
                    DetailImage ||
                    DetailVideo ||
                    DetailDocument ||
                    WebLink)
                    ? true
                    : false
                }
                IsRequired={ItemIsRequired}
                IsCustomContent={true}
                ItemHeading={
                  InfoType === "system" || ContentId
                    ? this.state.detailTitle
                    : "New Custom Item"
                }
                IsNewCustomItem={
                  InfoType === "system" || ContentId ? false : true
                }
              />
            </div>
            <button
              className="infoitem-edit-button"
              onClick={
                SaveStatus === `${this.state.itemUniqueId}-editing`
                  ? this.onCancel
                  : this.onEdit
              }
            >
              {SaveStatus === `${this.state.itemUniqueId}-editing` ||
              SaveStatus === `${this.state.itemUniqueId}-fetching`
                ? "Cancel"
                : "Edit"}
            </button>
          </div>

          <div
            className={
              SaveStatus === `${this.state.itemUniqueId}-editing` ||
              SaveStatus === `${this.state.itemUniqueId}-fetching`
                ? "hide"
                : "show"
            }
          >
            {(!ItemData || (ItemData && !DetailTextPreview)) && (
              <div className="infoitem-preview-container">
                <label className="infoitem-text-preview">
                  {ItemDescription}
                </label>
              </div>
            )}
            {ItemData && DetailTextPreview && (
              <>
                <div className="infoitem-preview-container">
                  <div className="infoitem-text-preview">
                    {DetailTextPreview}
                  </div>
                </div>

                <div className="infoitem-attachments-container">
                  {DetailImage && (
                    <div className="infoitem-attachments-banner-container">
                      <div className="infoitem-preview-container">
                        <div
                          className={
                            this.state.imagePreviewLoading
                              ? "infoitem-image-preview-container-loading"
                              : "infoitem-image-preview-container"
                          }
                        >
                          <img
                            className={
                              this.state.imagePreviewLoading
                                ? "infoitem-image-preview-loading infoitem-image-preview-loading-animate"
                                : "infoitem-image-preview"
                            }
                            src={DetailImage}
                            alt={
                              this.state.imagePreviewLoading
                                ? ""
                                : this.state.detailLabel
                            }
                            onLoad={this.onImagePreviewLoad}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {DetailVideo && (
                    <div className="infoitem-attachments-banner-container">
                      <div className="infoitem-preview-container">
                        {/*   <label className="infoitem-form-label"> Video </label> */}
                        <div className={"infoitem-image-preview-container"}>
                          <video
                            className={"infoitem-image-preview"}
                            src={DetailVideo}
                            type="video/mp4"
                          >
                            This browser does not support video. Try Chrome or
                            Safari instead.
                          </video>
                        </div>
                      </div>
                    </div>
                  )}

                  {DetailDocument && (
                    <div className="infoitem-attachments-banner-container">
                      <div className={"infoitem-image-preview-container"}>
                        {/* <label className="infoitem-form-label"> PDF </label> */}
                        <div className={"infoitem-image-preview"}>
                          <PDFPreview DetailDocument={DetailDocument} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {(SaveStatus === `${this.state.itemUniqueId}-editing` ||
            SaveStatus === `${this.state.itemUniqueId}-fetching`) && (
            <div className="infoitem-value">{ItemDescription}</div>
          )}

          <div
            className={
              SaveStatus === `${this.state.itemUniqueId}-editing` ||
              SaveStatus === `${this.state.itemUniqueId}-fetching`
                ? "show"
                : "hide"
            }
          >
            <div className="infoitem-form-container">
              <div className="infoitem-form">
                <>
                  {(ItemShowTitle || InfoType === "custom") && (
                    <div className="infoitem-form-inner-container-textarea">
                      <label className="infoitem-form-label">
                        {ItemTitleLabel}
                      </label>

                      <input
                        required
                        className="infoitem-text-input"
                        name={"detailTitle"}
                        placeholder={"Enter your title here"}
                        value={this.state.detailTitle}
                        onChange={this.onTextChange}
                      />
                    </div>
                  )}

                  {
                    // Media Uploader
                  }
                  {ItemShowMedia && (
                    <>
                      <div className="infoitem-attachments-outer-container">
                        <label className="infoitem-form-label">
                          Add an image, video or pdf (if no image or video is
                          added then your App will display the default category
                          icon)
                        </label>

                        <div className="infoitem-attachments-container">
                          <div className="infoitem-attachments-banner-container">
                            <div className="infoitem-preview-container">
                              <label className="infoitem-form-label"> </label>
                              <input
                                type="file"
                                key={this.state.imageInputKey || ""} // Required to clear file input value
                                id={`file-upload-image-${this.state.itemUniqueId}`}
                                name={"DetailImage"}
                                className="infoitem-image-input"
                                placeholder={"Upload image"}
                                value={this.state.emptyImageField.name} // Needs to be set programatically to empty
                                onChange={this.onImageUploaderChange}
                              />

                              <label
                                htmlFor={`file-upload-image-${this.state.itemUniqueId}`}
                                className="custom-file-upload"
                              >
                                {this.state.selectedImageFile &&
                                  this.state.showImage && (
                                    <img
                                      className="infoitem-image"
                                      // src={`data:image/jpeg;base64,${this.state.selectedImageFile}`}
                                      src={this.state.imageFilePreview}
                                      alt={this.state.detailLabel}
                                    />
                                  )}
                                {!this.state.selectedImageFile &&
                                  this.state.showImage &&
                                  DetailImage && (
                                    <img
                                      className="infoitem-image"
                                      src={DetailImage}
                                      alt={this.state.detailLabel}
                                    />
                                  )}
                                {this.state.showImage &&
                                  (this.state.selectedImageFile ||
                                    DetailImage) && (
                                    <button
                                      className="infoitem-file-delete-button"
                                      onClick={this.onImageDelete}
                                    >
                                      <BinIcon className="infoitem-file-delete-icon" />
                                    </button>
                                  )}

                                {((!this.state.selectedImageFile &&
                                  !DetailImage) ||
                                  !this.state.showImage) && (
                                  <div className="custom-file-upload-inner-container">
                                    <CameraIcon className="custom-file-upload-icon" />

                                    <div className="custom-file-upload-text">
                                      Upload image
                                    </div>
                                  </div>
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="infoitem-attachments-banner-container">
                            <div className="infoitem-preview-container">
                              <label className="infoitem-form-label"> </label>

                              {this.state.selectedVideoFile &&
                                this.state.showVideo && (
                                  <>
                                    <input
                                      type="file"
                                      key={this.state.videoInputKey || ""} // Required to clear file input value
                                      id={`file-upload-video-${this.state.itemUniqueId}`}
                                      name={"DetailVideo"}
                                      className="infoitem-image-input"
                                      placeholder={"Upload video"}
                                      value={this.state.emptyVideoField.name} // Needs to be set programatically to empty
                                      onChange={this.onVideoUploaderChange}
                                      disabled
                                    />
                                    <label
                                      htmlFor={`file-upload-video-${this.state.itemUniqueId}`}
                                      className="custom-file-upload"
                                    >
                                      {this.state.showVideo &&
                                        (this.state.selectedVideoFile ||
                                          DetailVideo) && (
                                          <button
                                            className="infoitem-file-delete-button"
                                            onClick={this.onVideoDelete}
                                          >
                                            <BinIcon className="infoitem-file-delete-icon" />
                                          </button>
                                        )}

                                      <video
                                        className="infoitem-preview-video"
                                        src={`data:video/webm;base64,${this.state.selectedVideoFile}`}
                                        type="video/mp4"
                                      >
                                        This browser does not support video. Try
                                        Chrome or Safari instead.
                                      </video>
                                    </label>
                                  </>
                                )}

                              {!this.state.selectedVideoFile &&
                                this.state.showVideo &&
                                DetailVideo && (
                                  <>
                                    <input
                                      type="file"
                                      key={this.state.videoInputKey || ""} // Required to clear file input value
                                      id={`file-upload-video-${this.state.itemUniqueId}`}
                                      name={"DetailVideo"}
                                      className="infoitem-image-input"
                                      placeholder={"Upload video"}
                                      value={this.state.emptyVideoField.name} // Needs to be set programatically to empty
                                      onChange={this.onVideoUploaderChange}
                                      disabled
                                    />
                                    <label
                                      htmlFor={`file-upload-video-${this.state.itemUniqueId}`}
                                      className="custom-file-upload"
                                    >
                                      {this.state.showVideo &&
                                        (this.state.selectedVideoFile ||
                                          DetailVideo) && (
                                          <button
                                            className="infoitem-file-delete-button"
                                            onClick={this.onVideoDelete}
                                          >
                                            <BinIcon className="infoitem-file-delete-icon" />
                                          </button>
                                        )}

                                      <video
                                        className="infoitem-preview-video"
                                        src={DetailVideo}
                                        type="video/mp4"
                                      >
                                        This browser does not support video. Try
                                        Chrome or Safari instead.
                                      </video>
                                    </label>
                                  </>
                                )}

                              {((!this.state.selectedVideoFile &&
                                !DetailVideo) ||
                                !this.state.showVideo) && (
                                <>
                                  <input
                                    type="file"
                                    key={this.state.videoInputKey || ""} // Required to clear file input value
                                    id={`file-upload-video-${this.state.itemUniqueId}`}
                                    name={"DetailVideo"}
                                    className="infoitem-image-input"
                                    placeholder={"Upload video"}
                                    value={this.state.emptyVideoField.name} // Needs to be set programatically to empty
                                    onChange={this.onVideoUploaderChange}
                                  />
                                  <label
                                    htmlFor={`file-upload-video-${this.state.itemUniqueId}`}
                                    className="custom-file-upload"
                                  >
                                    <div className="custom-file-upload-inner-container">
                                      <VideoIcon className="custom-file-upload-icon" />

                                      <div className="custom-file-upload-text">
                                        Upload video
                                      </div>
                                    </div>
                                  </label>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="infoitem-attachments-banner-container">
                            <div className="infoitem-preview-container">
                              <label className="infoitem-form-label"> </label>
                              <input
                                type="file"
                                key={this.state.documentInputKey || ""} // Required to clear file input value
                                id={`file-upload-document-${this.state.itemUniqueId}`}
                                name={"DetailDocument"}
                                className="infoitem-image-input"
                                placeholder={"Upload document"}
                                value={this.state.emptyDocumentField.name} // Needs to be set programatically to empty
                                onChange={this.onDocumentUploaderChange}
                              />

                              <label
                                htmlFor={`file-upload-document-${this.state.itemUniqueId}`}
                                className="custom-file-upload"
                              >
                                {this.state.selectedDocumentFile &&
                                  this.state.showDocument && (
                                    <PDFPreview
                                      //    DetailDocument={`data:image/jpeg;base64,${this.state.selectedDocumentFile}`}
                                      DetailDocument={
                                        this.state.documentFilePreview
                                      }
                                    />
                                  )}
                                {!this.state.selectedDocumentFile &&
                                  this.state.showDocument &&
                                  DetailDocument && (
                                    <PDFPreview
                                      DetailDocument={DetailDocument}
                                    />
                                  )}
                                {this.state.showDocument &&
                                  (this.state.selectedDocumentFile ||
                                    DetailDocument) && (
                                    <button
                                      className="infoitem-file-delete-button"
                                      onClick={this.onDocumentDelete}
                                    >
                                      <BinIcon className="infoitem-file-delete-icon" />
                                    </button>
                                  )}

                                {((!this.state.selectedDocumentFile &&
                                  !DetailDocument) ||
                                  !this.state.showDocument) && (
                                  <div className="custom-file-upload-inner-container">
                                    <DocumentIcon className="custom-file-upload-icon" />

                                    <div className="custom-file-upload-text">
                                      Upload PDF
                                    </div>
                                  </div>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="infoitem-form-inner-container-textarea">
                    <label className="infoitem-form-label">
                      Add a text description
                    </label>

                    <InfoItemMultiTextEditor
                      htmlText={this.state.detailText}
                      placeHolder={"Enter your text here"}
                      onHtmlTextChange={this.onHtmlTextChange}
                    />
                  </div>

                  {ItemShowWebLink && (
                    <div className="infoitem-form-inner-container-textarea">
                      <label className="infoitem-form-label">
                        {ItemWebLinkLabel}
                      </label>

                      <input
                        className="infoitem-text-input"
                        name={"webLink"}
                        placeholder={
                          'Enter full url beginning with "http" here (leave blank if not applicable)'
                        }
                        value={this.state.webLink}
                        onChange={this.onTextChange}
                      />
                    </div>
                  )}

                  {this.state.subContent && (
                    <InfoItemSubContent
                      contentId={ContentId}
                      PropertyId={PropertyId}
                      userId={userId}
                      DetailLabel={this.state.detailLabel}
                      isComponentShared={isComponentShared}
                      SharedContentProfileId={SharedContentProfileId}
                      subContent={this.state.subContent}
                      onSubContentStateChange={this.onSubContentStateChange}
                      onRemoveContentClick={this.onRemoveContentClick}
                      resetKey={this.state.subContentString}
                    />
                  )}

                  {ItemShowFeaturedContent && (
                    <div className="infoitem-form-inner-container-textarea">
                      <div className="form-checkbox-and-label-outer-wrapper">
                        <label className="form-checkbox-and-label-inner-wrapper">
                          <input
                            name="featuredChecked"
                            type="checkbox"
                            value={this.state.featuredContent}
                            checked={this.state.featuredContent}
                            onChange={this.handleCheckboxChange}
                          />
                          {ItemFeaturedContentLabel}
                          <span className="form-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  )}
                </>

                <div className="infoitem-form-inner-container-buttons">
                  {this.state.displaySaveButton && (
                    <button
                      onClick={this.onSubmit}
                      className="infoitem-save-button"
                    >
                      {SaveStatus === `${this.state.itemUniqueId}-fetching`
                        ? "Saving..."
                        : "Save"}
                    </button>
                  )}

                  {ContentId && (
                    <DeleteContentButton
                      {...this.props}
                      ContentId={ContentId}
                      propertyId={propertyId}
                      SharedContentProfileId={SharedContentProfileId}
                      isComponentShared={isComponentShared}
                      onStateChange={this.handleStateChange}
                      ItemUniqueId={ItemUniqueId}
                      ItemLabel={ItemUniqueId}
                      userId={userId}
                      imageFilename={this.state.imageFilename}
                      imageFile={this.state.selectedImageFile}
                      videoFilename={this.state.videoFilename}
                      videoFile={this.state.selectedVideoFile}
                      documentFilename={this.state.documentFilename}
                      documentFile={this.state.selectedDocumentFile}
                      ItemData={ItemData}
                      subContent={this.state.subContent}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="infoitem-section-break-wrapper">
            <div className="infoitem-section-break"></div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateContent: (
    contentInfo,
    contentId,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    userId,
    imageFilename,
    imageFile,
    videoFilename,
    videoFile,
    documentFilename,
    documentFile,
    subContent,
    linkedProperties
  ) =>
    dispatch(
      updateContent(
        contentInfo,
        contentId,
        ItemLabel,
        propertyId,
        SharedContentProfileId,
        userId,
        imageFilename,
        imageFile,
        videoFilename,
        videoFile,
        documentFilename,
        documentFile,
        subContent,
        linkedProperties
      )
    ),
  deleteUploadedFile: (fileUrl) => dispatch(deleteUploadedFile(fileUrl)),
  createContent: (
    contentInfo,
    ItemLabel,
    propertyId,
    SharedContentProfileId,
    userId,
    imageFilename,
    imageFile,
    videoFilename,
    videoFile,
    documentFilename,
    documentFile,
    subContent
  ) =>
    dispatch(
      createContent(
        contentInfo,
        ItemLabel,
        propertyId,
        SharedContentProfileId,
        userId,
        imageFilename,
        imageFile,
        videoFilename,
        videoFile,
        documentFilename,
        documentFile,
        subContent
      )
    ),

  setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
});

export default connect(null, mapDispatchToProps)(InfoItemMulti);
