import { appConfig } from "../data/appConfig";

export function loadLinkedInTracking() {
  const _linkedin_partner_id = appConfig.linkedInInsightId;
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(_linkedin_partner_id);

  // Set up the tracking function if it doesn't exist
  if (!window.lintrk) {
    window.lintrk = function (a, b) {
      window.lintrk.q.push([a, b]);
    };
    window.lintrk.q = [];
  }

  // Create and insert the LinkedIn tracking script
  const s = document.getElementsByTagName("script")[0];
  const b = document.createElement("script");
  b.type = "text/javascript";
  b.async = true;
  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
  s.parentNode.insertBefore(b, s);

  // Add the noscript fallback
  const noscript = document.createElement("noscript");
  const img = document.createElement("img");
  img.height = 1;
  img.width = 1;
  img.style.display = "none";
  img.alt = "";
  img.src = `https://px.ads.linkedin.com/collect/?pid=${appConfig.linkedInInsightId}&fmt=gif`;
  noscript.appendChild(img);
  document.body.appendChild(noscript);
}
