import React from "react";
import { appConfig } from "../data/appConfig";
import { hostName } from "../constants";

export default function CapterraPixel() {
  if (
    appConfig.capterraPixelId &&
    appConfig.capterraPixelKey &&
    hostName.includes("guideyapp.com")
  ) {
    return (
      <img
        src={`https://ct.capterra.com/capterra_tracker.gif?vid=${appConfig.capterraPixelId}&vkey=${appConfig.capterraPixelKey}`}
      />
    );
  } else {
    return <></>;
  }
}
