import React from "react";
import { connect } from "react-redux";
import { updateProperty } from "../redux/actions/userActions";
import { liveRootUrl } from "../constants.js";
import NotificationBanner from "./NotificationBanner";
import InfoItemCheckMark from "./InfoItemCheckMark";
import InfoItemPasscode from "./InfoItemPasscode";
import UpperRightArrowSquare from "../icons/UpperRightArrowSquare";
import LinkIcon from "../icons/LinkIcon";
import DownloadIcon from "../icons/DownloadIcon";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import "../styles/PropertyDetailsSection.css";
import "../styles/InfoPage.css";

class PropertyDetailsSection extends React.Component {
  state = {
    linkCopied: false,
  };

  componentDidMount = () => {
    const { propertyVanityId } = this.props;
    this.setState({ urlLink: `${liveRootUrl}${propertyVanityId}` });
  };

  onReactivateSubscription = (e) => {
    e.preventDefault();
    this.props.history.push("/subscription");
  };

  onPublish = (e) => {
    const { propertyId, ItemLabel } = this.props;
    const propertyInfo = { PublishedStatus: true };
    e.preventDefault();
    this.props.updateProperty(propertyInfo, propertyId, ItemLabel);
  };

  onUnpublish = (e) => {
    const { propertyId, ItemLabel } = this.props;
    const propertyInfo = { PublishedStatus: false };
    e.preventDefault();
    this.props.updateProperty(propertyInfo, propertyId, ItemLabel);
  };

  onEdit = (e) => {
    const { Target, propertyId } = this.props;
    e.preventDefault();
    this.props.history.push({
      pathname: Target,
      state: { propertyId: propertyId },
    });
  };

  onLinkCopy = () => {
    navigator.clipboard.writeText(this.state.urlLink);
    this.setState({ linkCopied: true });
    setTimeout(() => this.setState({ linkCopied: false }), 3000);
  };
  render() {
    const {
      SectionType,
      IsRequired,
      SectionTitle,
      SectionText,
      propertyVanityId,
      IsChecked,
      ShowActionButton,
      userData,
      PropertyActiveStatus,
      SaveStatus,
      ItemLabel,
      propertyDetails,
    } = this.props;

    return (
      <div className="section-details-page-container">
        {SectionType === "link" &&
          IsChecked &&
          propertyDetails &&
          !propertyDetails.QRCode && (
            <NotificationBanner
              Text={
                "Your customised web link and QR Code will appear here within 12 hours of creating your property"
              }
              DisplayButton={false}
              ButtonText={"Learn more"}
              Target={""}
              IconType={"Bell"}
              IconColour={"#1e8fc1"}
              TextColour={"#1e8fc1"}
              ButtonColour={"#1e8fc1"}
              BorderColour={"#1e8fc1"}
              {...this.props}
            />
          )}
        <div className="report-checkitem-outer-wrapper">
          <div className="report-checkitem-wrapper">
            <div className="report-checkitem-description-wrapper">
              <div className="report-checkitem-heading">
                <InfoItemCheckMark
                  IsChecked={IsChecked}
                  IsRequired={IsRequired}
                  IsCustomContent={true}
                  ItemHeading={SectionTitle}
                />
              </div>

              <div className="infopage-text-and-button-wrapper">
                <div className="infoitem-save-button-container-padding-left">
                  {SectionType === "link" && IsChecked && (
                    <>
                      <div className="infopage-link-wrapper">
                        <div className="infopage-link-box">
                          <div className="infopage-link-inner-wrapper">
                            <a
                              className="infopage-link"
                              id="infopage-link"
                              href={`${liveRootUrl}${propertyVanityId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`${liveRootUrl}${propertyVanityId}`}

                              <span>
                                <UpperRightArrowSquare className="infopage-link-inline-icon" />
                              </span>
                            </a>
                          </div>
                          <div
                            onClick={this.onLinkCopy}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="infopage-link-button">
                              {!this.state.linkCopied && (
                                <>
                                  <div className="infopage-link-label">
                                    Copy web link
                                  </div>

                                  <LinkIcon className="infopage-link-box-icon" />
                                </>
                              )}

                              {this.state.linkCopied && (
                                <>
                                  <div className="infopage-link-label">
                                    Link copied to clipboard
                                  </div>
                                  <CheckmarkIcon className="infopage-link-box-icon" />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {propertyDetails.QRCode && (
                        <a
                          href={propertyDetails.QRCode}
                          download={`${propertyDetails.PropertyID}-qr-code`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <div className="infopage-link-box">
                            <div className="infopage-qrcode-wrapper">
                              <img
                                className="infopage-qrcode-image"
                                src={propertyDetails.QRCode}
                                alt={propertyDetails.PropertyTitle}
                              />
                            </div>

                            <div className="infopage-link-button">
                              <div className="infopage-link-label">
                                Download QR Code
                              </div>

                              <DownloadIcon className="infopage-link-box-icon" />
                            </div>
                          </div>
                        </a>
                      )}

                      <InfoItemPasscode
                        propertyDetails={propertyDetails}
                        ItemLabel={"passcode"}
                        SaveStatus={SaveStatus}
                        PayloadA={"PassCode"}
                        propertyId={propertyDetails._id}
                      />

                      {
                        //
                        // <button
                        //  className="infoitem-delete-button"
                        //  onClick={this.onUnpublish}
                        //  >
                        //   {SaveStatus === `${ItemLabel}-fetching`
                        //     ? "Unpublishing..."
                        //     : "Unpublish"}
                        // </button>
                      }
                    </>
                  )}

                  {SectionText}
                </div>
              </div>

              {!ShowActionButton && SectionType !== "link" && (
                <button className="checkitem-edit-button" onClick={this.onEdit}>
                  Edit
                </button>
              )}

              {ShowActionButton && SectionType !== "link" && (
                <div className="infoitem-save-button-container-padding-left">
                  <button
                    className="infoitem-save-button"
                    onClick={this.onEdit}
                  >
                    Continue setup
                  </button>
                </div>
              )}

              {SectionType === "link" && ShowActionButton && (
                <div className="infoitem-save-button-container-padding-left">
                  {(!userData.SubscriptionActiveUser ||
                    !userData.SubscriptionActiveSystem) && (
                    <>
                      <div className="infopage-link-heading">
                        Your subscription has ended. Please setup your
                        subscription.
                      </div>

                      <button
                        className="infoitem-save-button"
                        onClick={this.onReactivateSubscription}
                      >
                        Setup now
                      </button>
                    </>
                  )}

                  {userData.SubscriptionActiveUser &&
                    userData.SubscriptionActiveSystem &&
                    PropertyActiveStatus &&
                    !propertyDetails.PublishedStatus && (
                      <>
                        <div className="infopage-link-heading">
                          This place has been hidden from guests. Please publish
                          it to make it live.
                        </div>

                        <button
                          className="infoitem-save-button"
                          onClick={this.onPublish}
                        >
                          {SaveStatus === `${ItemLabel}-fetching`
                            ? "Publishing..."
                            : "Publish"}
                        </button>
                      </>
                    )}

                  {userData.SubscriptionActiveUser &&
                    userData.SubscriptionActiveSystem &&
                    !PropertyActiveStatus && (
                      <>
                        <div className="infopage-link-heading">
                          This property has been deactivated and is hidden from
                          guests. To reactivate it please{" "}
                          <a href="https://www.airguideapp.com/contact.html/">
                            send us a message
                          </a>{" "}
                          and we will process your request as soon as possible.
                        </div>
                      </>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProperty: (propertyInfo, propertyId, ItemLabel) =>
    dispatch(updateProperty(propertyInfo, propertyId, ItemLabel)),
});

export default connect(null, mapDispatchToProps)(PropertyDetailsSection);
