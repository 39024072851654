import React from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { getSubscriptionData } from "../redux/actions/userActions";
import Loading from "./Loading";
import DashboardSubscriptionInfo from "./DashboardSubscriptionInfo";
import CreateSubscription from "./CreateSubscription";
import Breadcrumb from "./Breadcrumb";
// import Table from "./Table";
import "../styles/DisplayCanvas.css";
import "../styles/InfoItem.css";
import "../styles/CreateSubscription.css";

class DashboardSubscription extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    const { userEmailId } = this.props;
    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: `${userEmailId} - Subscription and Payment`,
    });
    this.props.getSubscriptionData();
  }

  render() {
    const {
      userData,
      stripeData,
      StripeDataLoaded,
      propertyData,
      geoLocationData,
      SaveStatus,
    } = this.props;

    //  const currency = userData.DefaultCurrency
    //   ? userData.DefaultCurrency
    //   : geoLocationData.currency.toLowerCase();
    // console.log(currency);

    //  const currencySymbol = currency === "gbp" ? "£" : "$";

    if (!StripeDataLoaded) {
      return <Loading />;
    }

    if (StripeDataLoaded) {
      //console.log("stripeData", stripeData);
      return (
        <>
          <div className="canvas-with-header-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <Breadcrumb
                {...this.props}
                PreviousPageTitle={"Dashboard"}
                PreviousPagePath={"/"}
                CurrentSectionTitle={"Subscription and Payment"}
              />

              {userData.StripeSubscriptionID && (
                <DashboardSubscriptionInfo
                  {...this.props}
                  userData={userData}
                  stripeData={stripeData}
                  SaveStatus={SaveStatus}
                />
              )}

              {!userData.StripeSubscriptionID && (
                <CreateSubscription
                  {...this.props}
                  userData={userData}
                  stripeData={stripeData}
                  propertyData={propertyData}
                  geoLocationData={geoLocationData}
                  SaveStatus={SaveStatus}
                  ItemLabel={"create-subscription"}
                />
              )}
            </div>
          </div>
        </>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionData: () => dispatch(getSubscriptionData()),
  };
};

export default connect(null, mapDispatchToProps)(DashboardSubscription);
