import { appConfig } from "./data/appConfig";

// Set liveStatus "true" for live data or "false" for test data
export const liveStatus = true;

// System Id to identify application type
export const systemId = "100000";

// App release version
export const appVersion = "40.0.0";

export const hostName = window.location.hostname;

// Workflow API
export const workflowApiRoot = liveStatus
  ? `${process.env.REACT_APP_WORKFLOW_API_LIVE}`
  : `${process.env.REACT_APP_WORKFLOW_API_DEV}`;

// Data API
export const dataApiRoot = liveStatus
  ? `${process.env.REACT_APP_DATA_API_LIVE}`
  : `${process.env.REACT_APP_DATA_API_DEV}`;

// Backend API Secret Token
export const apiSecretToken = `${process.env.REACT_APP_API_SECRET_TOKEN}`;

// Stripe
export const stripePublicKey = liveStatus
  ? "pk_live_OlTVgOvlqoXrbGo0MUeSPYnK00AzO8nrBk"
  : "pk_test_2y5fSZ44UO2WBtqrtIgBaPyU00IQ18XTcK";

export const stripeProductId = "prod_KO7xU8xPkXw0u4";

// Root URL for live End-user App
export const liveRootUrl = liveStatus
  ? `${appConfig.guideUrl}/`
  : `${appConfig.guideUrl}/`;

// Root URL for live End-user App
export const previewRootUrl = liveStatus
  ? `${appConfig.previewUrl}/`
  : `${appConfig.previewUrl}/`;

const topLevelDomainWithSub = window.location.href.split("//")[1];
export const topLevelDomain = topLevelDomainWithSub.substring(
  topLevelDomainWithSub.indexOf(".")
);

// Storage Root
export const storageRootUrl = `${process.env.REACT_APP_STORAGE_ROOT}`;

export const storageApiKey = `${process.env.REACT_APP_STORAGE_ACCESS_KEY}`;

// CDN Root
export const cdnRootUrl = `${process.env.REACT_APP_CDN_ROOT}`;

// Google Place Photo Service
export const googlePlacePhotoApiRootUrl =
  "https://maps.googleapis.com/maps/api/place/photo?";

// Google Maps API Key
export const googleMapsApiKey = liveStatus
  ? `${process.env.REACT_APP_GOOGLE_MAPS_API}`
  : `${process.env.REACT_APP_GOOGLE_MAPS_API}`;

// Google Places API Key
export const googlePlacesApiKey = liveStatus
  ? `${process.env.REACT_APP_GOOGLE_PLACES_API}`
  : `${process.env.REACT_APP_GOOGLE_PLACES_API}`;

// Google Analytics 4 Tracking Id
export const googleAnalytics4Id = liveStatus
  ? `${process.env.REACT_APP_GOOGLE_ANALYTICS4_TRACKING_ID_LIVE}`
  : `${process.env.REACT_APP_GOOGLE_ANALYTICS4_TRACKING_ID_DEV}`;

// What 3 Words API Key
export const whatThreeWordsApiKey = liveStatus
  ? `${process.env.REACT_APP_WHAT_THREE_WORDS_KEY}`
  : `${process.env.REACT_APP_WHAT_THREE_WORDS_KEY}`;

// Hotjar Id
export const hotjarId = "5018746";

// Pusher
const pusherLive = true;
export const pusherAppId = pusherLive
  ? `${process.env.REACT_APP_PUSHER_ID}`
  : "123456";
export const pusherApiKey = pusherLive
  ? `${process.env.REACT_APP_PUSHER_AUTH_KEY}`
  : "123456";
export const pusherSecretKey = pusherLive
  ? `${process.env.REACT_APP_PUSHER_SECRET_KEY}`
  : "123456";

// Archive & Legacy

// Google Maps API Key
//export const googleMapsApiKey = liveStatus
//? `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY_LIVE}`
//: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY_DEV}`;

// Google Analytics Id
export const googleAnalyticsId = liveStatus
  ? `${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_LIVE}`
  : `${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID_DEV}`;
