import React from "react";
//import ReactGA from "react-ga";
//import { googleAnalyticsId } from "../constants.js";
import Breadcrumb from "./Breadcrumb";
import PropertyDetailsSection from "./PropertyDetailsSection";
import DeletePropertyButton from "./DeletePropertyButton";
import PreviewButton from "./PreviewButton";
//import AdBanner from "./AdBanner";
//import GoogleReview from "../images/review-us-google.png";
import { connect } from "react-redux";
import { getCustomContent } from "../redux/actions/userActions";
import { setSaveStatus } from "../redux/actions/userActions";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/InfoItem.css";

//ReactGA.initialize(googleAnalyticsId);

class PropertyDetailsHome extends React.Component {
  // Scroll to top on page load and log pageview
  componentDidMount = () => {
    window.scrollTo(0, 0);
    //  ReactGA.pageview(window.location.pathname + window.location.search);
  };

  checkIfChecked = (label, data) => {
    var filter = data.filter((Item) => {
      return Item && Item.categorylabel && Item.categorylabel === label;
    });
    if (filter[0]) return true;
    if (!filter[0]) return false;
  };

  render() {
    // Extract VanityPropertyId from current page's URL
    const vanityPropertyId = window.location.pathname.split("/")[2];

    const {
      SaveStatus,
      propertyData,
      userData,
      systemContent,
      systemCategories,
      customContent,
    } = this.props;

    //  const ItemLabel = "PropertyDetailsHome";

    // Extract placeholder property details from SystemContent
    const propertyDetailsPlaceholderFiltered = systemContent.filter(
      (DisplayContent) => {
        return DisplayContent.DetailLabel === "property-details-placeholder";
      }
    );
    const propertyDetailsPlaceholder = propertyDetailsPlaceholderFiltered[0];

    // Use Detail label to lookup custom content
    const propertyDataFiltered = propertyData.filter((Item, index) => {
      return Item.PropertyID === vanityPropertyId;
    });

    // console.log("propertyDataFiltered", propertyDataFiltered);

    var propertyDetails = null;
    if (propertyDataFiltered) {
      propertyDetails = propertyDataFiltered[0];
    }

    //   const propertyDetails = propertyDataFiltered[0];
    const propertyId = this.props.PropertyId;
    if (propertyDetails) {
      //  const propertyId = this.props.PropertyId; // propertyDataFiltered[0]._id;
      //   console.log("propertyId", propertyId);
      // Filter custom content by synthetic property id
      //  console.log("customContent", customContent);
      const customContentFilterOne = customContent.map((element) => {
        return {
          ...element,
          LinkedProperty: element.LinkedProperty.filter(
            (subElement) => subElement === propertyId
          ),
        };
      });
      const customContentFilterTwo = customContentFilterOne.filter((Item) => {
        return Item.LinkedProperty[0] === propertyId;
      });

      // Declare variables for checkmarks

      const basicSetupIsChecked =
        propertyDetails.PropertyTitle &&
        propertyDetails.PropertyCoverImage &&
        propertyDetails.GeoLocationFormattedAddress &&
        propertyDetails.PropertyDisplayAddress &&
        ((propertyDetails.PropertyHexPrimary &&
          propertyDetails.PropertyHexSecondary) ||
          propertyDetails.CompanyProfile)
          ? true
          : false;

      const CurrentSectionTitle = propertyDetails.PropertyTitle
        ? propertyDetails.PropertyTitle
        : "Unamed Place";

      const initialContentFilter = customContentFilterTwo.filter((item) => {
        return (
          item.DetailText ||
          item.DetailTextDescribe ||
          item.DetailTextLocate ||
          item.DetailTextOperate ||
          item.WebLink ||
          item.DetailVideo ||
          item.DetailImage ||
          item.DetailDocument
        );
      });

      // Return all categories found in "Custom Content" database
      const allCustomCategories = initialContentFilter.map((item) => {
        return {
          categorylabel: item.CategoryLabel,
        };
      });

      // Filter out empty and unwanted category labels from "Custom Content"
      const filteredCustomCategories = allCustomCategories.filter((item) => {
        return item.categorylabel !== undefined && item.categorylabel !== null;
      });

      // Return all data from "Content Categories" database
      const allContentCategories = systemCategories;

      // Filter "Content Categories" by the categories found in "Custom Content"
      var firstFilterContentCategories = [];

      for (var firstArray in allContentCategories) {
        for (var firstFilter in filteredCustomCategories) {
          if (
            allContentCategories[firstArray].CategoryLabel ===
            filteredCustomCategories[firstFilter].categorylabel
          ) {
            firstFilterContentCategories.push({
              categorylabel: allContentCategories[firstArray].CategoryLabel,
              parentcategorylabel:
                allContentCategories[firstArray].ParentCategoryLabel,
            });
          }
        }
      }

      // Lookup parent categories from categories found above
      var secondFilterContentCategories = [];

      for (var secondArray in allContentCategories) {
        for (var secondFilter in firstFilterContentCategories) {
          if (
            allContentCategories[secondArray].CategoryLabel ===
            firstFilterContentCategories[secondFilter].parentcategorylabel
          ) {
            secondFilterContentCategories.push({
              categorylabel: allContentCategories[secondArray].CategoryLabel,
              parentcategorylabel:
                allContentCategories[secondArray].ParentCategoryLabel,
            });
          }
        }
      }

      // Return unique categories found in the above
      var uniqueContentCategories = [];
      secondFilterContentCategories.forEach(function (item) {
        var i = uniqueContentCategories.findIndex(
          (x) => x.categorylabel === item.categorylabel
        );
        if (i <= -1) {
          uniqueContentCategories.push({
            categorylabel: item.categorylabel,
          });
        }
      });

      var liveStatusIsChecked = false;
      if (
        userData.SubscriptionActiveUser &&
        userData.SubscriptionActiveSystem &&
        propertyDetails.PropertyActiveStatus &&
        propertyDetails.PublishedStatus
      ) {
        liveStatusIsChecked = true;
      }

      var activeSectionTitle = "Inactive";
      if (liveStatusIsChecked) {
        activeSectionTitle = "Live";
      }

      const fromPage = this.props.history.location.state
        ? this.props.history.location.state.from
        : "dashboard";
      const PreviousPageTitle =
        fromPage === "dashboard"
          ? "Dashboard"
          : fromPage === "all-properties"
          ? "All Properties"
          : "Dashboard";
      const PreviousPagePath =
        fromPage === "dashboard"
          ? "/"
          : fromPage === "all-properties"
          ? "/properties"
          : "/";

      return (
        <>
          <div className="canvas-with-header-and-footer">
            <div className="canvas-with-header-and-footer-inner-container">
              <div className="breadcrumb-header-and-preview-button-container">
                <div className="breadcrumb-and-header-container">
                  <Breadcrumb
                    {...this.props}
                    PreviousPageTitle={PreviousPageTitle}
                    PreviousPagePath={PreviousPagePath}
                    CurrentSectionTitle={CurrentSectionTitle}
                  />
                  <h1>
                    {propertyDetails.PropertyTitle
                      ? propertyDetails.PropertyTitle
                      : propertyDetailsPlaceholder.DetailTitle}
                  </h1>
                </div>

                <PreviewButton
                  Style={"solid"}
                  Heading={"Preview guide"}
                  PropertyVanityId={propertyDetails.PropertyID}
                />
              </div>

              <div className="content-and-ad-banner-container">
                <div className="heading-and-image-container">
                  <img
                    className="infopage-image"
                    src={
                      propertyDetails.PropertyCoverImage
                        ? propertyDetails.PropertyCoverImage
                        : propertyDetailsPlaceholder.DetailImage
                    }
                    alt={
                      propertyDetails.PropertyTitle
                        ? propertyDetails.PropertyTitle
                        : propertyDetailsPlaceholder.DetailTitle
                    }
                  />

                  <PropertyDetailsSection
                    {...this.props}
                    SectionType={"link"}
                    IsChecked={liveStatusIsChecked}
                    PropertyActiveStatus={propertyDetails.PropertyActiveStatus}
                    PublishedStatus={propertyDetails.PublishedStatus}
                    userData={userData}
                    propertyDetails={propertyDetails}
                    IsRequired={true}
                    ShowActionButton={liveStatusIsChecked ? false : true}
                    SectionTitle={activeSectionTitle}
                    //      SectionText={activeSectionText}
                    SaveStatus={SaveStatus}
                    propertyVanityId={propertyDetails.PropertyID}
                    propertyId={propertyDetails._id}
                  />
                </div>

                {
                  //userData.StripeSubscriptionID && (
                  //<AdBanner
                  //  Image={GoogleReview}
                  //  Text={"Like what we do? Help others decide"}
                  //  ButtonText={"Review now"}
                  //   Target={"https://g.page/r/CdEPZoIQ9K0YEAg/review"}
                  //  />
                  // )}
                }

                {
                  // end content & ad
                }
                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  propertyDetails={propertyDetails}
                  SectionType={"non-link"}
                  IsChecked={basicSetupIsChecked}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked && !basicSetupIsChecked ? true : false
                  }
                  SectionTitle={"Basic Setup"}
                  SectionText={"Property, location and host details"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/property-details`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                {userData.MessagingActiveStatus && (
                  <>
                    <PropertyDetailsSection
                      {...this.props}
                      SectionType={"non-link"}
                      IsChecked={propertyDetails.CommsActiveStatus}
                      IsRequired={false}
                      ShowActionButton={false}
                      SectionTitle={"Messaging"}
                      SectionText={"Edit messaging settings for this property"}
                      SaveStatus={SaveStatus}
                      propertyVanityId={propertyDetails.PropertyID}
                      propertyId={propertyDetails._id}
                      Target={`/property/${vanityPropertyId}/messaging`}
                    />

                    <div className="infoitem-container">
                      <div className="section-break-wrapper">
                        <div className="section-break"></div>
                      </div>
                    </div>
                  </>
                )}

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "arrival",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    !this.checkIfChecked("arrival", uniqueContentCategories)
                  }
                  SectionTitle={"Welcome and Arrival"}
                  SectionText={"Welcome message and arrival information"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/arrival`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "place",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    !this.checkIfChecked("place", uniqueContentCategories)
                  }
                  SectionTitle={"About the Place"}
                  SectionText={"Information about household items"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/place`}
                  ParentCategoryLabel={"place-guide"}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "local-area",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    !this.checkIfChecked("local-area", uniqueContentCategories)
                  }
                  SectionTitle={"Local Area Recommendations"}
                  SectionText={"Local amenities, attractions and dining"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/local-area`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "services",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    !this.checkIfChecked("services", uniqueContentCategories)
                  }
                  SectionTitle={"Services and Supplies"}
                  SectionText={"Onsite services and additional supplies"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/services`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "store",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked("services", uniqueContentCategories) &&
                    !this.checkIfChecked("store", uniqueContentCategories)
                  }
                  SectionTitle={"Store"}
                  SectionText={"Sell items to your guests"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/store`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "safety",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked("services", uniqueContentCategories) &&
                    !this.checkIfChecked("safety", uniqueContentCategories)
                  }
                  SectionTitle={"Safety and Security"}
                  SectionText={"Safety and security features"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/safety`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "about-us",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked("safety", uniqueContentCategories) &&
                    this.checkIfChecked("services", uniqueContentCategories) &&
                    !this.checkIfChecked("about-us", uniqueContentCategories)
                  }
                  SectionTitle={"About Us"}
                  SectionText={"Information about the host"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/about-us`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "important-documents",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked("safety", uniqueContentCategories) &&
                    this.checkIfChecked("services", uniqueContentCategories) &&
                    this.checkIfChecked("about-us", uniqueContentCategories) &&
                    !this.checkIfChecked(
                      "important-documents",
                      uniqueContentCategories
                    )
                  }
                  SectionTitle={"Important documents"}
                  SectionText={"Important documents relating to the property"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/important-documents`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "emergency-contacts",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked("safety", uniqueContentCategories) &&
                    this.checkIfChecked("services", uniqueContentCategories) &&
                    this.checkIfChecked("about-us", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "important-documents",
                      uniqueContentCategories
                    ) &&
                    !this.checkIfChecked(
                      "emergency-contacts",
                      uniqueContentCategories
                    )
                  }
                  SectionTitle={"Emergency Contacts"}
                  SectionText={"Emergency contact details"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/emergency-contacts`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <PropertyDetailsSection
                  {...this.props}
                  SectionType={"non-link"}
                  IsChecked={this.checkIfChecked(
                    "faq",
                    uniqueContentCategories
                  )}
                  IsRequired={false}
                  ShowActionButton={
                    liveStatusIsChecked &&
                    basicSetupIsChecked &&
                    this.checkIfChecked("arrival", uniqueContentCategories) &&
                    this.checkIfChecked("place", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "local-area",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked("safety", uniqueContentCategories) &&
                    this.checkIfChecked("services", uniqueContentCategories) &&
                    this.checkIfChecked("about-us", uniqueContentCategories) &&
                    this.checkIfChecked(
                      "important-documents",
                      uniqueContentCategories
                    ) &&
                    this.checkIfChecked(
                      "emergency-contacts",
                      uniqueContentCategories
                    ) &&
                    !this.checkIfChecked("faq", uniqueContentCategories)
                  }
                  SectionTitle={"FAQ"}
                  SectionText={"Frequently Asked Questions"}
                  SaveStatus={SaveStatus}
                  propertyVanityId={propertyDetails.PropertyID}
                  propertyId={propertyDetails._id}
                  Target={`/property/${vanityPropertyId}/faq`}
                />

                <div className="infoitem-container">
                  <div className="section-break-wrapper">
                    <div className="section-break"></div>
                  </div>
                </div>

                <DeletePropertyButton {...this.props} propertyId={propertyId} />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      //    this.props.history.push("/");
      return <></>;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomContent: (appLabel, propertyId) =>
      dispatch(getCustomContent(appLabel, propertyId)),
    setSaveStatus: (payload) => dispatch(setSaveStatus(payload)),
  };
};

export default connect(null, mapDispatchToProps)(PropertyDetailsHome);
