import React from "react";
import { connect } from "react-redux";
import { getAnalyticsData } from "../redux/actions/userActions";
import getDatesInRange from "../functions/getDatesInRange";
import countPageViews from "../functions/countPageViews";
import countSessions from "../functions/countSessions";
import formatDateLabel from "../functions/formatDateLabel";
import Loading from "../components/Loading";
import Breadcrumb from "../components/Breadcrumb";
import Table from "../components/Table";
import "../styles/DisplayCanvas.css";
import "../styles/InfoPage.css";
import "../styles/Referrals.css";

class AnalyticsPage extends React.Component {
  state = {
    dateLabels: [],
    selectedProperty: "",
    selectedDateRange: "last-7-days",
    startDate: null,
    endDate: null,
    dateRanges: [],
  };

  refreshData = (userId, propertyId, dateRangeId) => {
    // console.log("dateRangeId", dateRangeId);

    var currentDate = new Date();
    const dateRanges = [
      {
        id: "last-7-days",
        nameLabel: "Last 7 Days",
        startDate: new Date(currentDate - 6 * 24 * 60 * 60 * 1000),
        endDate: currentDate,
      },
      // {
      // id: "today",
      // nameLabel: "Today",
      // startDate: new Date(currentDate.setHours(0, 0, 0, 0)),
      // endDate: currentDate
      // },
      {
id: "november-2024",
nameLabel: "November 2024",
startDate: new Date("2024-11-01"),
endDate:
currentDate > new Date("2024-11-30")
? new Date("2024-11-30")
: currentDate,
},
{
id: "october-2024",
nameLabel: "October 2024",
startDate: new Date("2024-10-01"),
endDate: new Date("2024-10-31"),
},
{
id: "september-2024",
nameLabel: "September 2024",
startDate: new Date("2024-09-01"),
endDate: new Date("2024-09-30"),
},
{
id: "august-2024",
nameLabel: "August 2024",
startDate: new Date("2024-08-01"),
endDate: new Date("2024-08-31"),
},
{
id: "july-2024",
nameLabel: "July 2024",
startDate: new Date("2024-07-01"),
endDate: new Date("2024-07-31"),
},
      {
        id: "june-2024",
        nameLabel: "June 2024",
        startDate: new Date("2024-06-01"),
        endDate: new Date("2024-06-30"),
      },
      {
        id: "may-2024",
        nameLabel: "May 2024",
        startDate: new Date("2024-05-01"),
        endDate: new Date("2024-05-31"),
      },
      {
        id: "april-2024",
        nameLabel: "April 2024",
        startDate: new Date("2024-04-01"),
        endDate: new Date("2024-04-30"),
      },
      {
        id: "march-2024",
        nameLabel: "March 2024",
        startDate: new Date("2024-03-01"),
        endDate: new Date("2024-03-31"),
      },
      {
        id: "february-2024",
        nameLabel: "February 2024",
        startDate: new Date("2024-02-01"),
        endDate: new Date("2024-02-29"),
      },
      {
        id: "january-2024",
        nameLabel: "January 2024",
        startDate: new Date("2024-01-01"),
        endDate: new Date("2024-01-31"),
      },
      {
        id: "december-2023",
        nameLabel: "December 2023",
        startDate: new Date("2023-12-01"),
        endDate: new Date("2023-12-31"),
      },
      {
        id: "november-2023",
        nameLabel: "November 2023",
        startDate: new Date("2023-11-01"),
        endDate: new Date("2023-11-30"),
      },
      {
        id: "october-2023",
        nameLabel: "October 2023",
        startDate: new Date("2023-10-01"),
        endDate: new Date("2023-10-31"),
      },
      {
        id: "september-2023",
        nameLabel: "September 2023",
        startDate: new Date("2023-09-01"),
        endDate: new Date("2023-09-30"),
      },
      {
        id: "august-2023",
        nameLabel: "August 2023",
        startDate: new Date("2023-08-01"),
        endDate: new Date("2023-08-31"),
      },
      {
        id: "july-2023",
        nameLabel: "July 2023",
        startDate: new Date("2023-07-01"),
        endDate: new Date("2023-07-31"),
      },
      {
        id: "june-2023",
        nameLabel: "June 2023",
        startDate: new Date("2023-06-01"),
        endDate: new Date("2023-06-30"),
      },
      {
        id: "may-2023",
        nameLabel: "May 2023",
        startDate: new Date("2023-05-01"),
        endDate: new Date("2023-05-31"),
      },
      {
        id: "april-2023",
        nameLabel: "April 2023",
        startDate: new Date("2023-04-01"),
        endDate: new Date("2023-04-30"),
      },
      {
        id: "march-2023",
        nameLabel: "March 2023",
        startDate: new Date("2023-03-01"),
        endDate: new Date("2023-03-31"),
      },
      {
        id: "february-2023",
        nameLabel: "February 2023",
        startDate: new Date("2023-02-01"),
        endDate: new Date("2023-02-28"),
      },
      {
        id: "january-2023",
        nameLabel: "January 2023",
        startDate: new Date("2023-01-01"),
        endDate: new Date("2023-01-31"),
      },
      {
        id: "december-2022",
        nameLabel: "December 2022",
        startDate: new Date("2022-12-01"),
        endDate: new Date("2022-12-31"),
      },
      {
        id: "november-2022",
        nameLabel: "November 2022",
        startDate: new Date("2022-11-01"),
        endDate: new Date("2022-11-30"),
      },
      {
        id: "october-2022",
        nameLabel: "October 2022",
        startDate: new Date("2022-10-01"),
        endDate: new Date("2022-10-31"),
      },
      {
        id: "september-2022",
        nameLabel: "September 2022",
        startDate: new Date("2022-09-01"),
        endDate: new Date("2022-09-30"),
      },
      {
        id: "august-2022",
        nameLabel: "August 2022",
        startDate: new Date("2022-08-01"),
        endDate: new Date("2022-08-31"),
      },
      {
        id: "july-2022",
        nameLabel: "July 2022",
        startDate: new Date("2022-07-01"),
        endDate: new Date("2022-07-31"),
      },
      {
        id: "june-2022",
        nameLabel: "June 2022",
        startDate: new Date("2022-06-01"),
        endDate: new Date("2022-06-30"),
      },
      {
        id: "may-2022",
        nameLabel: "May 2022",
        startDate: new Date("2022-05-01"),
        endDate: new Date("2022-05-31"),
      },
      {
        id: "april-2022",
        nameLabel: "April 2022",
        startDate: new Date("2022-04-01"),
        endDate: new Date("2022-04-30"),
      },
    ];

    const dateRangesFiltered = dateRanges.filter(
      (item) => item.id === dateRangeId
    );
    const rangeStartDate = dateRangesFiltered[0].startDate;
    const rangeEndDate = dateRangesFiltered[0].endDate;

    //  console.log("rangeStartDate:", rangeStartDate, "rangeEndDate:", rangeEndDate);

    this.props.getAnalyticsData(
      userId,
      propertyId,
      rangeStartDate,
      rangeEndDate
    );

    const dateLabels = getDatesInRange(rangeStartDate, rangeEndDate);
    dateLabels.sort(function (a, b) {
      return b > a ? 1 : a > b ? -1 : 0;
    });

    // console.log("labels", dateLabels);
    this.setState({
      dateLabels: dateLabels,
      selectedProperty: propertyId,
      selectedDateRange: dateRangeId,
      dateRanges: dateRanges,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // ReactGA.pageview(window.location.pathname + window.location.search);

    const propertyId = this.state.selectedProperty;
    const { userData } = this.props;
    const userId = userData._id;

    //   var currentDate = new Date();
    // var startDays = 6; // Days you want to subtract
    // var endDays = 0; // Days you want to add
    //  var startDate = new Date(currentDate - startDays * 24 * 60 * 60 * 1000);
    // var startDate = new Date("2022-03-30");
    // var endDate = new Date(currentDate);
    //  var endDate = new Date("2022-04-05");
    // console.log(startDate);

    this.refreshData(userId, propertyId, this.state.selectedDateRange);

    // this.props.getAnalyticsData(userId, propertyId, startDate, endDate);

    //  const dateLabels = getDatesInRange(startDate, endDate);
    //   dateLabels.sort(function (a, b) {
    //     return b > a ? 1 : a > b ? -1 : 0;
    //   });

    //  console.log("labels", dateLabels);
    //   this.setState({
    //    dateLabels: dateLabels,
    //   startDate: startDate,
    //   endDate: endDate
    // });
  }

  render() {
    const {
      analyticsData,
      AnalyticsDataLoaded,
      propertyData,
      userData,
      DataLoadPercent,
    } = this.props;
    const userId = userData._id;
    // console.log("analyticsData", analyticsData)
    if (analyticsData) {
      analyticsData.forEach((object) => {
        object.dateLookup = new Date(object["Created Date"])
          .toISOString()
          .split("T")[0];
      });

      var labelLookups = this.state.dateLabels;
      var analyticsIsoData = analyticsData;

      var displayData = labelLookups.map((date) => ({
        dateLookup: date,
        dateLabel: formatDateLabel(date),
        totalPageViews: countPageViews(analyticsIsoData, "dateLookup", date),
        totalSessions: countSessions(analyticsIsoData, date),
        desktopSessions: countSessions(
          analyticsIsoData,
          date,
          "DeviceType",
          "desktop"
        ),
        tabletSessions: countSessions(
          analyticsIsoData,
          date,
          "DeviceType",
          "tablet"
        ),
        mobileQrSessions: countSessions(
          analyticsIsoData,
          date,
          "DeviceType",
          "mobile",
          "UtmSource",
          "qr-code"
        ),
        mobileWeblinkSessions:
          countSessions(analyticsIsoData, date, "DeviceType", "mobile") -
          countSessions(
            analyticsIsoData,
            date,
            "DeviceType",
            "mobile",
            "UtmSource",
            "qr-code"
          ),
      }));
    }

    return (
      <>
        <div className="canvas-with-header-and-footer">
          <div className="canvas-with-header-and-footer-inner-container">
            <Breadcrumb
              {...this.props}
              PreviousPageTitle={"Dashboard"}
              PreviousPagePath={"/"}
              CurrentSectionTitle={"Guide Analytics"}
            />
            <h1> Guide Analytics </h1>

            <div style={{ height: "20px" }}></div>

            <select
              id="select-date-range"
              className="table-select"
              onChange={(e) =>
                this.refreshData(
                  userId,
                  this.state.selectedProperty,
                  e.target.value
                )
              }
            >
              {this.state.dateRanges.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.nameLabel}
                </option>
              ))}
            </select>

            {propertyData.length > 1 && (
              <select
                id="select-property"
                className="table-select"
                onChange={(e) =>
                  //   this.setState({ selectedProperty: e.target.value })
                  // {console.log("dates", userId, e.target.value, this.state.startDate, this.state.endDate)
                  // }

                  this.refreshData(
                    userId,
                    e.target.value,
                    this.state.selectedDateRange
                  )
                }
              >
                <option key={"all-properties"} value={""}>
                  {"All Properties"}
                </option>
                {propertyData.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.PropertyTitle}
                  </option>
                ))}
              </select>
            )}

            {!AnalyticsDataLoaded && (
              <Loading
                LoadingText={
                  DataLoadPercent
                    ? `Loading data... ${DataLoadPercent}`
                    : "Loading data..."
                }
                LoadingCanvas={"table-loading-canvas"}
              />
            )}

            {AnalyticsDataLoaded && (
              <Table
                {...{
                  key: displayData.dateLabel,
                  data: displayData,
                  fields: {
                    dateLabel: "Date",
                    mobileWeblinkSessions: "Mobile Visits (Weblink)",
                    mobileQrSessions: "Mobile Visits (QR Code)",
                    desktopSessions: "Desktop Visits",
                    tabletSessions: "Tablet Visits",
                    totalSessions: "Total Visits",
                    totalPageViews: "Total Page Views",
                  },
                }}
              />
            )}
          </div>
        </div>
      </>
    );
    //   }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAnalyticsData: (userId, propertyId, startDate, endDate) =>
      dispatch(getAnalyticsData(userId, propertyId, startDate, endDate)),
  };
};

export default connect(null, mapDispatchToProps)(AnalyticsPage);
