// Import Libraries
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { hotjar } from "react-hotjar";
import ReactPixel from "react-facebook-pixel";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Pusher from "pusher-js";
// Import Constants
import { appVersion, pusherApiKey, hotjarId, hostName } from "./constants.js";
import { appConfig } from "./data/appConfig";
// Import Tracking
import { loadLinkedInTracking } from "./tracking/loadLinkedInTracking";
// Import Functions
import { autoLogin, getPropertyId } from "./redux/actions/userActions";
import { refreshConversations } from "./data/refreshConversations";
// Import Components
import Loading from "./components/Loading";
import LoginForm from "./components/LoginForm";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import DashboardHeader from "./components/DashboardHeader";
import DashboardHome from "./components/DashboardHome";
import DashboardPersonalInfo from "./components/DashboardPersonalInfo";
import DashboardLoginInfo from "./components/DashboardLoginInfo";
import DashboardWelcome from "./components/DashboardWelcome";
// import DashboardSubscriptionInfo from "./components/DashboardSubscriptionInfo";
import DashboardSubscription from "./components/DashboardSubscription";
import ReferralsPage from "./pages/ReferralsPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import DashboardMessaging from "./components/DashboardMessaging";
//import ConversationDetails from "./components/ConversationDetails";
import ConversationDetailsTwo from "./components/ConversationDetailsTwo";
import DashboardPropertyManagers from "./components/DashboardPropertyManagers";
import DashboardAllProperties from "./components/DashboardAllProperties";
import DashboardCompanyProfile from "./components/DashboardCompanyProfile";
import DashboardManagerProfileLoader from "./components/DashboardManagerProfileLoader";
import PropertyDetailsHomeLoader from "./components/PropertyDetailsHomeLoader";
import PropertyDetailsBasicsLoader from "./components/PropertyDetailsBasicsLoader";
import PropertyDetailsArrivalLoader from "./components/PropertyDetailsArrivalLoader";
import PropertyDetailsLocalLoader from "./components/PropertyDetailsLocalLoader";
import PropertyDetailsCatAutoLoader from "./components/PropertyDetailsCatAutoLoader";
import PropertyDetailsInfoAutoLoader from "./components/PropertyDetailsInfoAutoLoader";
import PropertyMessagingSettings from "./components/PropertyMessagingSettings";
import SharedContentHomeLoader from "./components/SharedContentHomeLoader";
//import AllContent from "./pages/AllContent";
//import ContentCategory from "./pages/ContentCategory";
import SharedLocalAreaProfiles from "./components/SharedLocalAreaProfiles";
import ItemDeleted from "./components/ItemDeleted";
import CreateProperty from "./components/CreateProperty";
import CreateManager from "./components/CreateManager";
import CreateCompany from "./components/CreateCompany";
import CreateLocalAreaProfile from "./components/CreateLocalAreaProfile";
import PartnersLanding from "./components/PartnersLanding";
import CreateAccount from "./components/CreateAccount";
import SetupProperty from "./components/SetupProperty";
import SetupBasics from "./components/SetupBasics";
import SetupPayment from "./components/SetupPayment";
import SetupComplete from "./components/SetupComplete";
import CancellationPage from "./components/CancellationPage";

export default function App() {
  // Log App Version
  console.log("Client appVersion CDM", appVersion);

  // Declare State & Constants
  const dispatch = useDispatch();
  const [vanityPropertyId, setVanityPropertyId] = useState("");
  // const topLevelPath = window.location.pathname.split("/")[1];

  // Initial Mount
  useEffect(() => {
    if (hostName.includes("guideyapp.com")) {
      // console.log("hostName", hostName);
      if (hotjarId) {
        hotjar.initialize(hotjarId, 6);
      }
      const advancedMatching = {};
      const options = {
        autoConfig: true,
        debug: false,
      };
      const marketingConsent = true;
      ReactPixel.init(appConfig.metaPixelId, advancedMatching, options);
      if (marketingConsent) {
        ReactPixel.pageView();
        loadLinkedInTracking();
      } else {
        ReactPixel.revokeConsent();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initiate Login
  useEffect(() => {
    const url_string = window.location.href;
    var url = new URL(url_string);
    var urlId =
      window.location.pathname.split("/")[1] === "property"
        ? window.location.pathname.split("/")[2]
        : null;
    setVanityPropertyId(urlId);
    var login_token = url.searchParams.get("login_token");
    if (login_token) {
      sessionStorage.setItem("token", login_token);
    }
    if (vanityPropertyId && sessionStorage.getItem("token")) {
      dispatch(getPropertyId(vanityPropertyId));
    }
    if (vanityPropertyId && !sessionStorage.getItem("token")) {
      dispatch(autoLogin());
    }
    if (!vanityPropertyId) {
      dispatch(autoLogin());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Process Data
  const LoadingStatus = useSelector(
    (state) => state.userReducer.loading,
    shallowEqual
  );
  // console.log("LoadingStatus", LoadingStatus);
  const userLoggedIn = useSelector(
    (state) => state.userReducer.loggedIn,
    shallowEqual
  );

  const SaveStatus = useSelector(
    (state) => state.fetchReducer.saveStatus,
    shallowEqual
  );
  //console.log("SaveStatus", SaveStatus);
  const userState = useSelector(
    (state) => state.userReducer.user,
    shallowEqual
  );
  //console.log("userState", userState)

  const systemContent = useSelector(
    (state) => state.userReducer.user.systemContent,
    shallowEqual
  );

  const systemCategories = useSelector(
    (state) => state.userReducer.user.systemCategories,
    shallowEqual
  );
  const userData = useSelector(
    (state) => state.userReducer.user.userData,
    shallowEqual
  );

  const userEmail = userData ? userData.UserEmail : "";
  const userEmailId = userEmail
    ? userEmail.replace(/\./g, "-").replace(/@/g, "-").toLowerCase()
    : "";

  const customContent = useSelector(
    (state) => state.userReducer.user.linkedCustomContent,
    shallowEqual
  );
  const sharedContent = useSelector(
    (state) => state.userReducer.user.sharedCustomContent,
    shallowEqual
  );
  const sharedContentProfiles = useSelector(
    (state) => state.userReducer.user.sharedContentProfiles,
    shallowEqual
  );
  const companyData = useSelector(
    (state) => state.userReducer.user.linkedManagementCo,
    shallowEqual
  );
  const managerData = useSelector(
    (state) => state.userReducer.user.linkedPropertyManagers,
    shallowEqual
  );
  const propertyData = useSelector(
    (state) => state.userReducer.user.linkedProperties,
    shallowEqual
  );
  const geoLocationData = useSelector(
    (state) => state.userReducer.user.geoLocationData,
    shallowEqual
  );
  const CustomContentLoaded = useSelector(
    (state) => state.userReducer.customContentLoaded,
    shallowEqual
  );
  const CustomContentPropertyId = useSelector(
    (state) => state.userReducer.customContentPropertyId,
    shallowEqual
  );
  const linkedPropertiesLoaded = useSelector(
    (state) => state.userReducer.linkedPropertiesLoaded,
    shallowEqual
  );
  const SinglePropertyDataLoaded = useSelector(
    (state) => state.userReducer.singlePropertyDataLoaded,
    shallowEqual
  );
  const LinkedPropertyManagersLoaded = useSelector(
    (state) => state.userReducer.linkedPropertyManagersLoaded,
    shallowEqual
  );
  const LinkedManagementCoLoaded = useSelector(
    (state) => state.userReducer.linkedManagementCoLoaded,
    shallowEqual
  );
  const FetchStatus = useSelector(
    (state) => state.fetchReducer.fetchStatus,
    shallowEqual
  );
  const responseStatus = useSelector(
    (state) => state.userReducer.responseData,
    shallowEqual
  );
  const postResponseData = useSelector(
    (state) => state.fetchReducer.postResponseData,
    shallowEqual
  );
  const propertyId = useSelector(
    (state) => state.userReducer.propertyId,
    shallowEqual
  );
  const SharedContentLoaded = useSelector(
    (state) => state.userReducer.sharedContentLoaded,
    shallowEqual
  );
  const SharedContentLoadedId = useSelector(
    (state) => state.userReducer.sharedContentLoadedId,
    shallowEqual
  );
  const SharedPropertyContentProfileFilter = sharedContentProfiles
    ? sharedContentProfiles.filter((item) => {
        return item.PropertyContentProfile === true;
      })
    : null;
  const SharedPropertyContentProfileId =
    sharedContentProfiles &&
    SharedPropertyContentProfileFilter &&
    SharedPropertyContentProfileFilter[0]
      ? SharedPropertyContentProfileFilter[0]._id
      : null;
  const sharedLocalAreaContentProfiles = sharedContentProfiles
    ? sharedContentProfiles.filter((item) => {
        return item.LocalContentProfile === true;
      })
    : null;
  const AttachContentLoaded = useSelector(
    (state) => state.userReducer.attachContentLoaded,
    shallowEqual
  );
  const AttachProfilesLoaded = useSelector(
    (state) => state.userReducer.sharedContentProfilesLoaded,
    shallowEqual
  );
  const FileLoadStatus = useSelector(
    (state) => state.userReducer.fileLoadStatus,
    shallowEqual
  );
  const stripeData = useSelector(
    (state) => state.userReducer.user.subscriptionData,
    shallowEqual
  );
  const StripeDataLoaded = useSelector(
    (state) => state.userReducer.subscriptionDataLoaded,
    shallowEqual
  );
  const referralData = useSelector(
    (state) => state.userReducer.user.referralData,
    shallowEqual
  );
  const ReferralDataLoaded = useSelector(
    (state) => state.userReducer.referralDataLoaded,
    shallowEqual
  );
  const analyticsData = useSelector(
    (state) => state.userReducer.user.analyticsData,
    shallowEqual
  );
  const AnalyticsDataLoaded = useSelector(
    (state) => state.userReducer.analyticsDataLoaded,
    shallowEqual
  );
  const conversationData = useSelector(
    (state) => state.userReducer.user.conversationData,
    shallowEqual
  );
  // console.log("conversationData in app", conversationData)
  const ConversationDataLoaded = useSelector(
    (state) => state.userReducer.conversationDataLoaded,
    shallowEqual
  );
  const conversationsDataRefreshing = useSelector(
    (state) => state.userReducer.conversationsDataRefreshing,
    shallowEqual
  );
  const messageData = useSelector(
    (state) => state.userReducer.user.messageData,
    shallowEqual
  );
  // console.log("messageData in app", messageData)
  const MessageDataLoaded = useSelector(
    (state) => state.userReducer.messageDataLoaded,
    shallowEqual
  );
  const conversationMessagesRefreshing = useSelector(
    (state) => state.userReducer.conversationMessagesRefreshing,
    shallowEqual
  );
  const DataLoadPercent = useSelector(
    (state) => state.userReducer.dataLoadPercent,
    shallowEqual
  );

  // Initiate Pusher
  const [pusherState, setPusherState] = useState(null);
  const userId = userData ? userData._id : "";
  const messagingActiveStatus = userData
    ? userData.MessagingActiveStatus
    : false;
  useEffect(() => {
    if (userLoggedIn && messagingActiveStatus) {
      const pusher = new Pusher(pusherApiKey, {
        cluster: "eu",
      });
      setPusherState(pusher);
      console.log("pusher initiate", userId);
      var channel = pusher.subscribe(userId);
      channel.bind("new-message", function (data) {
        // alert(JSON.stringify(data));
        // console.log("App.js push data", data);
        refreshConversations(dispatch, userId);
        console.log("pusher refresh");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagingActiveStatus]);

  // PropertyId Update
  useEffect(() => {
    if (
      !vanityPropertyId &&
      window.location.pathname.split("/")[1] === "property"
    ) {
      var vanityPropertyIdUpdate =
        window.location.pathname.split("/")[1] === "property"
          ? window.location.pathname.split("/")[2]
          : null;
      if (vanityPropertyIdUpdate) {
        setVanityPropertyId(vanityPropertyIdUpdate);
        dispatch(getPropertyId(vanityPropertyIdUpdate));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vanityPropertyId]);

  //console.log("topLevelPath", topLevelPath)
  // Initiate Tidio

  if (!LoadingStatus) {
    if (appConfig.liveChatWidget === "tidio") {
      (function () {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = `https://code.tidio.co/${appConfig.liveChatWidgetId}.js`;
        s.async = true;

        document.body.append(s);
      })();
    }
  }

  // If Loading

  if (LoadingStatus) {
    return <Loading />;
  }

  // Loading Completed

  // Return Components

  return (
    <Router>
      <>
        <Route
          render={({ location }) => (
            <>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  classNames="fade"
                  timeout={100}
                >
                  <Route exact path="/loading" render={(props) => <></>} />
                </CSSTransition>
              </TransitionGroup>
            </>
          )}
        />
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} classNames="fade" timeout={100}>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <>
                      {!userLoggedIn && (
                        <>
                          <LoginForm
                            userLoggedIn={userLoggedIn}
                            loading={LoadingStatus}
                            FetchStatus={FetchStatus}
                            responseStatus={responseStatus}
                            {...props}
                          />
                        </>
                      )}

                      {userLoggedIn && (!userState || !propertyData) && (
                        <Loading />
                      )}

                      {userLoggedIn && userState && propertyData && (
                        <>
                          <DashboardHeader
                            userData={userData}
                            systemContent={systemContent}
                            pusher={pusherState}
                            {...props}
                          />
                          <DashboardHome
                            // responseStatus={responseStatus}
                            userLoggedIn={userLoggedIn}
                            userData={userData}
                            userEmailId={userEmailId}
                            propertyData={propertyData}
                            companyData={companyData}
                            managerData={managerData}
                            systemContent={systemContent}
                            SharedContentProfileId={
                              SharedPropertyContentProfileId
                            }
                            {...props}
                          />
                        </>
                      )}
                    </>
                  )}
                />
              </CSSTransition>
            </TransitionGroup>
          )}
        />

        <Switch>
          {userLoggedIn && (
            <Route
              exact
              path="/create-account"
              render={(props) => (
                <>
                  <Redirect from="/create-account" to="/welcome" />
                </>
              )}
            />
          )}
          {!userLoggedIn && (
            <Route
              exact
              path="/create-account"
              render={(props) => (
                <>
                  <CreateAccount
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    SaveStatus={SaveStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}
        </Switch>

        <Switch>
          {userLoggedIn && (
            <Route
              exact
              path="/forgot-password"
              render={(props) => (
                <>
                  <Redirect from="/forgot-password" to="/" />
                </>
              )}
            />
          )}
          {!userLoggedIn && (
            <>
              <Route
                exact
                path="/forgot-password"
                render={(props) => (
                  <>
                    <ForgotPassword
                      userLoggedIn={userLoggedIn}
                      loading={LoadingStatus}
                      FetchStatus={FetchStatus}
                      SaveStatus={SaveStatus}
                      {...props}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>
        <Switch>
          {userLoggedIn && (
            <Route
              exact
              path="/reset-password"
              render={(props) => (
                <>
                  <Redirect from="/reset-password" to="/" />
                </>
              )}
            />
          )}
          {!userLoggedIn && (
            <>
              <Route
                exact
                path="/reset-password"
                render={(props) => (
                  <>
                    <ResetPassword
                      userLoggedIn={userLoggedIn}
                      loading={LoadingStatus}
                      FetchStatus={FetchStatus}
                      SaveStatus={SaveStatus}
                      {...props}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/personal-information"
              render={(props) => (
                <>
                  <Redirect from="/personal-information" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <>
              <Route
                exact
                path="/personal-information"
                render={(props) => (
                  <>
                    <DashboardHeader
                      userData={userData}
                      systemContent={systemContent}
                      {...props}
                    />
                    <DashboardPersonalInfo
                      {...props}
                      userData={userData}
                      Loading={LoadingStatus}
                      SaveStatus={SaveStatus}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/login-security"
              render={(props) => (
                <>
                  <Redirect from="/login-security" to="/" />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/login-security"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <DashboardLoginInfo
                    {...props}
                    userData={userData}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={["/subscription", "/subscription-success"]}
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && userData && (
            <Route
              exact
              path={["/subscription", "/subscription-success"]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <DashboardSubscription
                    {...props}
                    userData={userData}
                    userEmailId={userEmailId}
                    stripeData={stripeData}
                    StripeDataLoaded={StripeDataLoaded}
                    propertyData={propertyData}
                    geoLocationData={geoLocationData}
                    SaveStatus={SaveStatus}
                  />
                </>
              )}
            />
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/cancellation"
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <>
              <Route
                exact
                path="/cancellation"
                render={(props) => (
                  <>
                    <DashboardHeader
                      userData={userData}
                      systemContent={systemContent}
                      {...props}
                    />
                    <CancellationPage
                      {...props}
                      userData={userData}
                      userEmailId={userEmailId}
                      stripeData={stripeData}
                      StripeDataLoaded={StripeDataLoaded}
                      propertyData={propertyData}
                      geoLocationData={geoLocationData}
                      SaveStatus={SaveStatus}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/referrals"
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <>
              <Route
                exact
                path="/referrals"
                render={(props) => (
                  <>
                    <DashboardHeader
                      userData={userData}
                      systemContent={systemContent}
                      {...props}
                    />
                    <ReferralsPage
                      {...props}
                      userData={userData}
                      Loading={LoadingStatus}
                      SaveStatus={SaveStatus}
                      referralData={referralData}
                      geoLocationData={geoLocationData}
                      ReferralDataLoaded={ReferralDataLoaded}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/guide-analytics"
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <>
              <Route
                exact
                path="/guide-analytics"
                render={(props) => (
                  <>
                    <DashboardHeader
                      userData={userData}
                      systemContent={systemContent}
                      {...props}
                    />
                    <AnalyticsPage
                      {...props}
                      userData={userData}
                      Loading={LoadingStatus}
                      SaveStatus={SaveStatus}
                      analyticsData={analyticsData}
                      propertyData={propertyData}
                      //  geoLocationData={geoLocationData}
                      AnalyticsDataLoaded={AnalyticsDataLoaded}
                      DataLoadPercent={DataLoadPercent}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={["/messaging", "/messaging-settings"]}
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <>
              <Route
                exact
                path={["/messaging", "/messaging-settings"]}
                render={(props) => (
                  <>
                    <DashboardHeader
                      userData={userData}
                      systemContent={systemContent}
                      {...props}
                    />
                    <DashboardMessaging
                      {...props}
                      userData={userData}
                      Loading={LoadingStatus}
                      SaveStatus={SaveStatus}
                      conversationData={conversationData}
                      propertyData={propertyData}
                      //  geoLocationData={geoLocationData}
                      ConversationDataLoaded={ConversationDataLoaded}
                      DataLoadPercent={DataLoadPercent}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/messaging/:messageId"
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <>
              <Route
                exact
                path="/messaging/:messageId"
                render={(props) => (
                  <>
                    <DashboardHeader
                      userData={userData}
                      systemContent={systemContent}
                      {...props}
                    />
                    <ConversationDetailsTwo
                      {...props}
                      userData={userData}
                      Loading={LoadingStatus}
                      SaveStatus={SaveStatus}
                      conversationData={conversationData}
                      messageData={messageData}
                      MessageDataLoaded={MessageDataLoaded}
                      propertyData={propertyData}
                      //  geoLocationData={geoLocationData}
                      ConversationDataLoaded={ConversationDataLoaded}
                      conversationsDataRefreshing={conversationsDataRefreshing}
                      conversationMessagesRefreshing={
                        conversationMessagesRefreshing
                      }
                      DataLoadPercent={DataLoadPercent}
                    />
                  </>
                )}
              />
            </>
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/properties"
              render={(props) => (
                <>
                  <Redirect from="/properties" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && (!userState || !propertyData) && <Loading />}

          {userLoggedIn && userState && propertyData && (
            <Route
              exact
              path="/properties"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <DashboardAllProperties
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    systemContent={systemContent}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/property-managers"
              render={(props) => (
                <>
                  <Redirect from="/property-managers" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property-managers"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <DashboardPropertyManagers
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    systemContent={systemContent}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/company-profile"
              render={(props) => (
                <>
                  <Redirect from="/company-profile" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/company-profile"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <DashboardCompanyProfile
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    propertyData={propertyData}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    LinkedManagementCoLoaded={LinkedManagementCoLoaded}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/manager-profile/:managerVanityId"
              render={(props) => (
                <>
                  <Redirect from="/manager-profile/:managerVanityId" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/manager-profile/:managerVanityId"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <DashboardManagerProfileLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    LinkedPropertyManagersLoaded={LinkedPropertyManagersLoaded}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/shared-content/:propertyId"
              render={(props) => (
                <>
                  <Redirect from="/shared-content/:propertyId" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && (!userState || !propertyData) && <Loading />}

          {userLoggedIn && userState && propertyData && (
            <Route
              exact
              path="/shared-content/:propertyId"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <SharedContentHomeLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemContent={systemContent}
                    systemCategories={systemCategories}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    //   PropertyId={SharedPropertyContentProfileId}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    CustomContentLoaded={CustomContentLoaded}
                    CustomContentPropertyId={CustomContentPropertyId}
                    ItemLabel={"shared-property-content"}
                    sharedContent={sharedContent}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                  />
                </>
              )}
            />
          )}
        </Switch>

        {
          // All Content WIP Start
        }

        {
          // All Content WIP End
        }

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/local-area-profiles"
              render={(props) => (
                <>
                  <Redirect from="/local-area-profiles" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/local-area-profiles"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <SharedLocalAreaProfiles
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    propertyData={propertyData}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    systemContent={systemContent}
                    sharedLocalAreaContentProfiles={
                      sharedLocalAreaContentProfiles
                    }
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/property/:propertyId"
              render={(props) => (
                <>
                  <Redirect from="/property/:propertyId" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && (!userState || !propertyData) && <Loading />}

          {userLoggedIn && userState && propertyData && (
            <Route
              exact
              path="/property/:propertyId"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />

                  <PropertyDetailsHomeLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemContent={systemContent}
                    systemCategories={systemCategories}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    PropertyId={propertyId}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    SinglePropertyDataLoaded={SinglePropertyDataLoaded}
                    CustomContentLoaded={CustomContentLoaded}
                    CustomContentPropertyId={CustomContentPropertyId}
                    sharedContent={sharedContent}
                    SharedContentLoaded={SharedContentLoaded}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/property-deleted"
              render={(props) => (
                <>
                  <Redirect from="/property-deleted" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property-deleted"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <ItemDeleted
                    {...props}
                    TypeTitle={"Property"}
                    PreviousPageTitle={"Dashboard"}
                    PreviousPagePath={"/"}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/manager"
              render={(props) => (
                <>
                  <Redirect from="/manager" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/manager"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <ItemDeleted
                    {...props}
                    TypeTitle={"Manager"}
                    PreviousPageTitle={"Property Managers"}
                    PreviousPagePath={"/property-managers"}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/local-area-profile-deleted"
              render={(props) => (
                <>
                  <Redirect from="/local-area-profile-deleted" to="/" />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/local-area-profile-deleted"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <ItemDeleted
                    {...props}
                    TypeTitle={"Local Area Profile"}
                    PreviousPageTitle={"Local Area Profiles"}
                    PreviousPagePath={"/local-area-profiles"}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && !userState && (
            <Route
              exact
              path="/property/:propertyId/property-details"
              render={(props) => (
                <>
                  <Redirect
                    from="/property/:propertyId/property-details"
                    to="/"
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property/:vanityPropertyId/property-details"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <PropertyDetailsBasicsLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    CustomContentLoaded={CustomContentLoaded}
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    FileLoadStatus={FileLoadStatus}
                  />
                </>
              )}
            />
          )}
        </Switch>
        {
          // Arrival property original start
        }
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={[
                "/property/:propertyId/arrival",
                //    "/shared-content/:propertyId/arrival"
              ]}
              render={(props) => (
                <>
                  <Redirect
                    from={
                      "/property/:propertyId/arrival" //||
                      //      "/shared-content/:propertyId/arrival"
                    }
                    to="/"
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={[
                "/property/:propertyId/arrival",
                //    "/shared-content/:propertyId/arrival"
              ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <PropertyDetailsArrivalLoader
                    {...props} // Needed for location.state & history for breadcrumb
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    SaveStatus={SaveStatus}
                    userData={userData}
                    propertyData={propertyData}
                    systemContent={systemContent}
                    customContent={customContent}
                    CustomContentLoaded={CustomContentLoaded}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    sharedContent={sharedContent}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                    sharedContentProfiles={sharedContentProfiles}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    isComponentShared={false} // Difference between paths
                    AttachContentLoaded={AttachContentLoaded} // Difference between paths
                    ItemLabel={"property-details-arrival"}
                    FileLoadStatus={FileLoadStatus}
                  />
                </>
              )}
            />
          )}
        </Switch>

        {
          // Arrival property original end
        }

        {
          // Arrival shared original start
        }

        {
          // Arrival shared original end
        }

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/property/:propertyId/messaging"
              render={(props) => (
                <>
                  <LoginForm
                    userLoggedIn={userLoggedIn}
                    loading={LoadingStatus}
                    FetchStatus={FetchStatus}
                    responseStatus={responseStatus}
                    {...props}
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && userData && (
            <Route
              exact
              path="/property/:propertyId/messaging"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <PropertyMessagingSettings
                    {...props} // Needed for location.state & history for breadcrumb
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    SaveStatus={SaveStatus}
                    userData={userData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemContent={systemContent}
                    customContent={customContent}
                    CustomContentLoaded={CustomContentLoaded}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    sharedContent={sharedContent}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                    sharedContentProfiles={sharedContentProfiles}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    isComponentShared={false} // Difference between paths
                    AttachContentLoaded={AttachContentLoaded} // Difference between paths
                    ItemLabel={"property-details-arrival"}
                    FileLoadStatus={FileLoadStatus}
                  />
                </>
              )}
            />
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/property/:vanityPropertyId/local-area"
              //    path={[
              //     "/property/:vanityPropertyId/local-area",
              //     "/local-area-profile/:sharedProfileLabel"
              //   ]}
              render={(props) => (
                <>
                  <Redirect
                    //    from={
                    //      "/property/:vanityPropertyId/local-area" ||
                    //     "/local-area-profile/:sharedProfileLabel"
                    //   }
                    from="/property/:vanityPropertyId/local-area"
                    to="/"
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property/:vanityPropertyId/local-area"
              //   path={[
              //     "/property/:vanityPropertyId/local-area",
              //     "/local-area-profile/:sharedProfileLabel"
              //   ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />

                  <PropertyDetailsLocalLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    CustomContentLoaded={CustomContentLoaded}
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    sharedContent={sharedContent}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                    sharedContentProfiles={sharedContentProfiles}
                    isComponentShared={false} // Difference between paths
                    AttachContentLoaded={AttachProfilesLoaded} // Difference between paths
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/local-area-profile/:sharedProfileLabel"
              //   path={[
              //      "/property/:vanityPropertyId/local-area",
              //     "/local-area-profile/:sharedProfileLabel"
              //   ]}
              render={(props) => (
                <>
                  <Redirect
                    //   from={
                    //     "/property/:vanityPropertyId/local-area" ||
                    //     "/local-area-profile/:sharedProfileLabel"
                    //   }
                    from="/local-area-profile/:sharedProfileLabel"
                    to="/"
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/local-area-profile/:sharedProfileLabel"
              // path={[
              //   "/property/:vanityPropertyId/local-area",
              //   "/local-area-profile/:sharedProfileLabel"
              // ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />

                  <PropertyDetailsLocalLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    CustomContentLoaded={CustomContentLoaded}
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    sharedContent={sharedContent}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                    isComponentShared={true}
                    sharedContentProfiles={sharedContentProfiles}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={[
                "/property/:vanityPropertyId/place",
                "/property/:vanityPropertyId/safety",
                "/property/:vanityPropertyId/services",
                "/property/:vanityPropertyId/store",
                "/property/:vanityPropertyId/about-us",
                "/property/:vanityPropertyId/important-documents",
                "/property/:vanityPropertyId/emergency-contacts",
                "/property/:vanityPropertyId/faq",
                //   "/shared-content/:vanityPropertyId/place",
                //   "/shared-content/:vanityPropertyId/safety",
                //   "/shared-content/:vanityPropertyId/services",
                //   "/shared-content/:vanityPropertyId/about-us",
                //   "/shared-content/:vanityPropertyId/important-documents"
              ]}
              render={(props) => (
                <>
                  <Redirect
                    from={
                      "/property/:vanityPropertyId/place" ||
                      "/property/:vanityPropertyId/safety" ||
                      "/property/:vanityPropertyId/services" ||
                      "/property/:vanityPropertyId/store" ||
                      "/property/:vanityPropertyId/about-us" ||
                      "/property/:vanityPropertyId/important-documents" ||
                      "/property/:vanityPropertyId/emergency-contacts" ||
                      "/property/:vanityPropertyId/faq"
                      //    "/shared-content/:vanityPropertyId/place" ||
                      //    "/shared-content/:vanityPropertyId/safety" ||
                      //    "/shared-content/:vanityPropertyId/services" ||
                      //    "/shared-content/:vanityPropertyId/about-us" ||
                      //    "/shared-content/:vanityPropertyId/important-documents"
                    }
                    to="/"
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={[
                "/property/:vanityPropertyId/place",
                "/property/:vanityPropertyId/safety",
                "/property/:vanityPropertyId/services",
                "/property/:vanityPropertyId/store",
                "/property/:vanityPropertyId/about-us",
                "/property/:vanityPropertyId/important-documents",
                "/property/:vanityPropertyId/emergency-contacts",
                "/property/:vanityPropertyId/faq",
                //   "/shared-content/:vanityPropertyId/place",
                //   "/shared-content/:vanityPropertyId/safety",
                //   "/shared-content/:vanityPropertyId/services",
                //   "/shared-content/:vanityPropertyId/about-us",
                //   "/shared-content/:vanityPropertyId/important-documents"
              ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />

                  <PropertyDetailsCatAutoLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    CustomContentLoaded={CustomContentLoaded}
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    sharedContent={sharedContent}
                    isComponentShared={false}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                    ItemLabel={"property-details-cat-autoloader"}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={[
                //    "/property/:vanityPropertyId/place",
                //     "/property/:vanityPropertyId/safety",
                //    "/property/:vanityPropertyId/services",
                //   "/property/:vanityPropertyId/about-us",
                //   "/property/:vanityPropertyId/important-documents",
                "/shared-content/:vanityPropertyId/place",
                "/shared-content/:vanityPropertyId/safety",
                "/shared-content/:vanityPropertyId/services",
                "/shared-content/:vanityPropertyId/store",
                "/shared-content/:vanityPropertyId/about-us",
                "/shared-content/:vanityPropertyId/important-documents",
                "/shared-content/:vanityPropertyId/emergency-contacts",
                "/shared-content/:vanityPropertyId/faq",
              ]}
              render={(props) => (
                <>
                  <Redirect
                    from={
                      //     "/property/:vanityPropertyId/place" ||
                      //    "/property/:vanityPropertyId/safety" ||
                      //    "/property/:vanityPropertyId/services" ||
                      //   "/property/:vanityPropertyId/about-us" ||
                      //   "/property/:vanityPropertyId/important-documents" //||
                      "/shared-content/:vanityPropertyId/place" ||
                      "/shared-content/:vanityPropertyId/safety" ||
                      "/shared-content/:vanityPropertyId/services" ||
                      "/shared-content/:vanityPropertyId/store" ||
                      "/shared-content/:vanityPropertyId/about-us" ||
                      "/shared-content/:vanityPropertyId/important-documents" ||
                      "/shared-content/:vanityPropertyId/emergency-contacts" ||
                      "/shared-content/:vanityPropertyId/faq"
                    }
                    to="/"
                  />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {
            // Place etc original shared start
          }

          {userLoggedIn && userState && (
            <Route
              exact
              path={[
                //   "/property/:vanityPropertyId/place",
                //   "/property/:vanityPropertyId/safety",
                //   "/property/:vanityPropertyId/services",
                //   "/property/:vanityPropertyId/about-us",
                //  "/property/:vanityPropertyId/important-documents",
                "/shared-content/:vanityPropertyId/arrival",
                "/shared-content/:vanityPropertyId/place",
                "/shared-content/:vanityPropertyId/safety",
                "/shared-content/:vanityPropertyId/services",
                "/shared-content/:vanityPropertyId/store",
                "/shared-content/:vanityPropertyId/about-us",
                "/shared-content/:vanityPropertyId/important-documents",
                "/shared-content/:vanityPropertyId/emergency-contacts",
                "/shared-content/:vanityPropertyId/faq",
              ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />

                  <PropertyDetailsCatAutoLoader
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    CustomContentLoaded={CustomContentLoaded}
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    sharedContent={sharedContent}
                    isComponentShared={true}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedContentLoadedId={SharedContentLoadedId}
                    ItemLabel={"property-details-cat-autoloader"}
                  />
                </>
              )}
            />
          )}
        </Switch>

        {
          // Place etc original shared end
        }

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={[
                "/property/:vanityPropertyId/place/*",
                "/property/:vanityPropertyId/safety/*",
                "/property/:vanityPropertyId/services/*",
                "/property/:vanityPropertyId/store/*",
                "/property/:vanityPropertyId/about-us/*",
                "/property/:vanityPropertyId/important-documents/*",
                "/property/:vanityPropertyId/emergency-contacts/*",
                "/property/:vanityPropertyId/faq/*",
                //    "/shared-content/:vanityPropertyId/place/*",
                //    "/shared-content/:vanityPropertyId/safety/*",
                //   "/shared-content/:vanityPropertyId/services/*",
                //   "/shared-content/:vanityPropertyId/about-us/*",
                //   "/shared-content/:vanityPropertyId/important-documents/*"
              ]}
              render={(props) => (
                <>
                  <Redirect
                    from={
                      "/property/:vanityPropertyId/place/*" ||
                      "/property/:vanityPropertyId/safety/*" ||
                      "/property/:vanityPropertyId/services/*" ||
                      "/property/:vanityPropertyId/store/*" ||
                      "/property/:vanityPropertyId/about-us/*" ||
                      "/property/:vanityPropertyId/important-documents/*" ||
                      "/property/:vanityPropertyId/emergency-contacts/*" ||
                      "/property/:vanityPropertyId/faq/*"
                      //     "/shared-content/:vanityPropertyId/place/*" ||
                      //     "/shared-content/:vanityPropertyId/safety/*" ||
                      //     "/shared-content/:vanityPropertyId/services/*" ||
                      //     "/shared-content/:vanityPropertyId/about-us/*" ||
                      //    "/shared-content/:vanityPropertyId/important-documents/*"
                    }
                    to="/"
                  />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={[
                "/property/:vanityPropertyId/place/*",
                "/property/:vanityPropertyId/safety/*",
                "/property/:vanityPropertyId/services/*",
                "/property/:vanityPropertyId/store/*",
                "/property/:vanityPropertyId/about-us/*",
                "/property/:vanityPropertyId/important-documents/*",
                "/property/:vanityPropertyId/emergency-contacts/*",
                "/property/:vanityPropertyId/faq/*",
                //   "/shared-content/:vanityPropertyId/place/*",
                //   "/shared-content/:vanityPropertyId/safety/*",
                //   "/shared-content/:vanityPropertyId/services/*",
                //   "/shared-content/:vanityPropertyId/about-us/*",
                //   "/shared-content/:vanityPropertyId/important-documents/*"
              ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <PropertyDetailsInfoAutoLoader
                    {...props} // Needed for location.state & history for breadcrumb
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    SaveStatus={SaveStatus}
                    userData={userData}
                    propertyData={propertyData}
                    systemContent={systemContent}
                    customContent={customContent}
                    CustomContentLoaded={CustomContentLoaded}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    systemCategories={systemCategories}
                    AttachContentLoaded={AttachContentLoaded}
                    sharedContent={sharedContent}
                    isComponentShared={false}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    ItemLabel={"property-details-info-autoloader"}
                    SharedContentLoadedId={SharedContentLoadedId}
                  />
                </>
              )}
            />
          )}
        </Switch>

        {
          // Place etc sub original shared start
        }
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={[
                //    "/property/:vanityPropertyId/place/*",
                //    "/property/:vanityPropertyId/safety/*",
                //    "/property/:vanityPropertyId/services/*",
                //   "/property/:vanityPropertyId/about-us/*",
                //   "/property/:vanityPropertyId/important-documents/*",
                "/shared-content/:vanityPropertyId/arrival/*",
                "/shared-content/:vanityPropertyId/place/*",
                "/shared-content/:vanityPropertyId/safety/*",
                "/shared-content/:vanityPropertyId/services/*",
                "/shared-content/:vanityPropertyId/store/*",
                "/shared-content/:vanityPropertyId/about-us/*",
                "/shared-content/:vanityPropertyId/important-documents/*",
                "/shared-content/:vanityPropertyId/emergency-contacts/*",
                "/shared-content/:vanityPropertyId/faq/*",
              ]}
              render={(props) => (
                <>
                  <Redirect
                    from={
                      //     "/property/:vanityPropertyId/place/*" ||
                      //     "/property/:vanityPropertyId/safety/*" ||
                      //     "/property/:vanityPropertyId/services/*" ||
                      //     "/property/:vanityPropertyId/about-us/*" ||
                      //    "/property/:vanityPropertyId/important-documents/*" //||
                      "/shared-content/:vanityPropertyId/arrival/*" ||
                      "/shared-content/:vanityPropertyId/place/*" ||
                      "/shared-content/:vanityPropertyId/safety/*" ||
                      "/shared-content/:vanityPropertyId/services/*" ||
                      "/shared-content/:vanityPropertyId/store/*" ||
                      "/shared-content/:vanityPropertyId/about-us/*" ||
                      "/shared-content/:vanityPropertyId/important-documents/*" ||
                      "/shared-content/:vanityPropertyId/emergency-contacts/*" ||
                      "/shared-content/:vanityPropertyId/faq/*"
                    }
                    to="/"
                  />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={[
                //   "/property/:vanityPropertyId/place/*",
                //    "/property/:vanityPropertyId/safety/*",
                //    "/property/:vanityPropertyId/services/*",
                //    "/property/:vanityPropertyId/about-us/*",
                //   "/property/:vanityPropertyId/important-documents/*",
                "/shared-content/:vanityPropertyId/arrival/*",
                "/shared-content/:vanityPropertyId/place/*",
                "/shared-content/:vanityPropertyId/safety/*",
                "/shared-content/:vanityPropertyId/services/*",
                "/shared-content/:vanityPropertyId/store/*",
                "/shared-content/:vanityPropertyId/about-us/*",
                "/shared-content/:vanityPropertyId/important-documents/*",
                "/shared-content/:vanityPropertyId/emergency-contacts/*",
                "/shared-content/:vanityPropertyId/faq/*",
              ]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <PropertyDetailsInfoAutoLoader
                    {...props} // Needed for location.state & history for breadcrumb
                    PropertyId={propertyId}
                    CustomContentPropertyId={CustomContentPropertyId}
                    SaveStatus={SaveStatus}
                    userData={userData}
                    propertyData={propertyData}
                    systemContent={systemContent}
                    customContent={customContent}
                    CustomContentLoaded={CustomContentLoaded}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                    systemCategories={systemCategories}
                    AttachContentLoaded={AttachContentLoaded}
                    sharedContent={sharedContent}
                    isComponentShared={true}
                    SharedContentLoaded={SharedContentLoaded}
                    SharedPropertyContentProfileId={
                      SharedPropertyContentProfileId
                    }
                    ItemLabel={"property-details-info-autoloader"}
                    SharedContentLoadedId={SharedContentLoadedId}
                  />
                </>
              )}
            />
          )}
        </Switch>

        {
          // Place etc sub original shared end
        }

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={["/create-property"]}
              render={(props) => (
                <>
                  <Redirect from={"/create-property"} to="/" />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={["/create-property"]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <CreateProperty
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                  />
                </>
              )}
            />
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={["/welcome"]}
              render={(props) => <Redirect from={"/welcome"} to="/" />}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={["/welcome"]}
              render={(props) => (
                <DashboardWelcome
                  {...props}
                  userData={userData}
                  companyData={companyData}
                  managerData={managerData}
                  propertyData={propertyData}
                  systemCategories={systemCategories}
                  systemContent={systemContent}
                  customContent={customContent}
                  Loading={LoadingStatus}
                  SaveStatus={SaveStatus}
                  linkedPropertiesLoaded={linkedPropertiesLoaded}
                />
              )}
            />
          )}
        </Switch>

        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={"/create-manager"}
              render={(props) => (
                <>
                  <Redirect from={"/create-manager"} to="/" />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={"/create-manager"}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <CreateManager
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    postResponseData={postResponseData}
                    LinkedPropertyManagersLoaded={LinkedPropertyManagersLoaded}
                    //      handleRedirect={this.props.handleRedirect}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={["/create-company"]}
              render={(props) => (
                <>
                  <Redirect from={"/create-company"} to="/" />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={["/create-company"]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <CreateCompany
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    linkedPropertiesLoaded={linkedPropertiesLoaded}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path={["/create-local-area-profile"]}
              render={(props) => (
                <>
                  <Redirect from={"/create-local-area-profile"} to="/" />
                </>
              )}
            />
          )}
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path={["/create-local-area-profile"]}
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={userData}
                    systemContent={systemContent}
                    {...props}
                  />
                  <CreateLocalAreaProfile
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    AttachProfilesLoaded={AttachProfilesLoaded}
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/setup-place"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={null}
                    systemContent={systemContent}
                    {...props}
                  />
                  <SetupProperty
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    customContent={customContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    postResponseData={postResponseData}
                  />
                </>
              )}
            />
          )}

          {!userLoggedIn && (
            <Route
              exact
              path="/setup-place"
              render={(props) => (
                <>
                  <Redirect from="/setup-place" to="/try-free" />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property/:vanityPropertyId/setup-basics"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={null}
                    systemContent={systemContent}
                    {...props}
                  />
                  <SetupBasics
                    {...props}
                    userData={userData}
                    companyData={companyData}
                    managerData={managerData}
                    propertyData={propertyData}
                    systemCategories={systemCategories}
                    systemContent={systemContent}
                    Loading={LoadingStatus}
                    SaveStatus={SaveStatus}
                    postResponseData={postResponseData}
                  />
                </>
              )}
            />
          )}

          {!userLoggedIn && (
            <Route
              exact
              path="/property/:vanityPropertyId/setup-basics"
              render={(props) => (
                <>
                  <Redirect
                    from="/property/:vanityPropertyId/setup-basics"
                    to="/try-free"
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property/:vanityPropertyId/setup-payment"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={null}
                    systemContent={systemContent}
                    {...props}
                  />
                  <SetupPayment
                    {...props}
                    userData={userData}
                    SaveStatus={SaveStatus}
                    ItemLabel={"setup-payment"}
                  />
                </>
              )}
            />
          )}

          {!userLoggedIn && (
            <Route
              exact
              path="/property/:vanityPropertyId/setup-payment"
              render={(props) => (
                <>
                  <Redirect
                    from="/property/:vanityPropertyId/setup-payment"
                    to="/try-free"
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/property/:vanityPropertyId/setup-complete"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={null}
                    systemContent={systemContent}
                    {...props}
                  />
                  <SetupComplete
                    {...props}
                    SaveStatus={SaveStatus}
                    userData={userData}
                    systemContent={systemContent}
                    propertyData={propertyData}
                  />
                </>
              )}
            />
          )}

          {!userLoggedIn && (
            <Route
              exact
              path="/property/:vanityPropertyId/setup-complete"
              render={(props) => (
                <>
                  <Redirect
                    from="/property/:vanityPropertyId/setup-complete"
                    to="/try-free"
                  />
                </>
              )}
            />
          )}
        </Switch>
        <Switch>
          {!userLoggedIn && (
            <Route
              exact
              path="/partners/:affiliateTrackingLabel"
              render={(props) => (
                <>
                  <DashboardHeader
                    userData={null}
                    systemContent={systemContent}
                    {...props}
                  />
                  <PartnersLanding {...props} SaveStatus={SaveStatus} />
                </>
              )}
            />
          )}

          {userLoggedIn && !userState && <Loading />}

          {userLoggedIn && userState && (
            <Route
              exact
              path="/partners/:affiliateTrackingLabel"
              render={(props) => (
                <>
                  <Redirect from="/partners/:affiliateTrackingLabel" to="/" />
                </>
              )}
            />
          )}
        </Switch>
      </>
    </Router>
  );
}
