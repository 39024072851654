import React from "react";
import { previewRootUrl } from "../constants.js";
import "../styles/PreviewButton.css";
import UpperRightArrowSquare from "../icons/UpperRightArrowSquare";

export default function PreviewButton(props) {
  const { onClick, PropertyVanityId, Heading, Style } = props;

  return (
    <>
      <a
        href={`${previewRootUrl}${PropertyVanityId}`}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={`preview-button-icon-left-${Style}`} onClick={onClick}>
          <div className="preview-button-icon-left-icon-and-label-wrapper">
            <div className={`preview-button-icon-left-wrapper-inner-${Style}`}>
              <UpperRightArrowSquare
                className={`preview-button-icon-left-icon-${Style}`}
              />
            </div>

            <div>{Heading}</div>
          </div>
        </div>
      </a>
    </>
  );
}

//            <CTAButtonIconLeft
//              Icon={UpperRightArrowSquare}
//             Style={"inverted"}
//             Heading={"View fullscreen"}
//          />
